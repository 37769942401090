/*
	Template Name: Minzel
	Author: techydevs
	Description: Minzel - Corporate, Business, Consulting HTML5 Template
	Tags: one-column, two-columns, three-columns, four-columns, custom-menu, featured-images, translation-ready
	Version: 1.0
*/

/* ---------------------------------------------
		Default Color Variables
 --------------------------------------------- */
 :root {
  --primary_color: #c39239;
  --secondary_color: #033132;
  --paragraph_color: #677286;
  --white_color: #ffffff;
  --black_color: #000000;
  --gray_color: #eeeeee;
  --dark_gray: #dddddd;
  --light_white: #f4f6fb;
  --green: #008000;
}

*:focus {
  outline: none;
}
*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
strong {
  color: var(--primary_color);
}
button {
  border: none;
  cursor: pointer;
}
input,
textarea {
  width: 100%;
  height: auto;
  line-height: inherit;
  padding: 12px 20px;
  font-size: 14px;
  color: var(--secondary_color);
  border: 1px solid var(--dark_gray);
  font-weight: 500;
  transition: all 0.3s;
  display: block;
  border-radius: 0.25rem;
  margin-bottom: 15px;
  /* font-family: "Barlow", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
select {
  /* font-family: "Barlow", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
textarea {
  height: 117px;
}
input:focus,
textarea:focus {
  border-color: var(--gray_color);
  box-shadow: 0 0;
}
input::placeholder,
textarea::placeholder {
  color: #999999;
}
a {
  text-decoration: none;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
sup {
  color: var(--primary_color);
  font-size: 16px;
}
body {
  font-size: 15px;
  line-height: 25px;
   /* font-family: "Barlow", sans-serif;  */
  font-family: "Montserrat", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
blockquote {
  background-color: var(--secondary_color);
  position: relative;
  z-index: 1;
  color: #a0aec6;
  border-left-color: #a0aec6;
  padding: 30px 35px 38px 35px;
  margin-top: 35px;
  margin-bottom: 30px;
}
blockquote:before {
  position: absolute;
  content: "";
  left: -5px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--white_color);
}
blockquote p {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  line-height: 28px;
  position: relative;
}
blockquote h3 {
  color: var(--white_color);
  font-weight: 600;
  margin-top: 24px;
}
blockquote h3 span {
  color: #a0aec6;
  font-size: 16px;
}
blockquote .quote-icon {
  font-size: 100px;
  position: absolute;
  right: 25px;
  bottom: 15px;
  opacity: 0.1;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.offset-2 {
  margin-left: 16.666667% !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  /* margin-left: 0px; */
  margin-left:auto;
}
@media (max-width: 767px) {
  .tabscards-mobile{
    display: none; /* Hide the non-mobile cards on mobile */
  }
  .container {
    max-width: 100%;
    width: 100%;
  }
  .offset-2 {
    margin-left: 0;
  }
  .header-area .header-menu-wrapper .header-menu-flex .header-menu-left a img {
    width: 120px;
  }
  .h-service {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}
@media (min-width: 768px) {
  .tabscards-mobile{
    display: none; /* Hide the non-mobile cards on mobile */
  }
  .container {
    max-width: 750px;
  }
  .offset-2 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .tabscards-mobile{
    display: none; /* Hide the non-mobile cards on mobile */
  }
  .desktop-only{
    display: none;
  }
  .container {
    max-width: 1200px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -45px;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 992px) {
  .offset-2 {
    margin-left: 0;
  }
  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*
        ScrollTop
*/
#back-to-top {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 24px;
  background-color: var(--white_color);
  color: var(--secondary_color);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  transition: all 0.5s;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  transform: scale(0);
  border: 1px solid #ddd;
}
#back-to-top.back-btn-shown {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
#back-to-top:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
}

/*
        Pagination
*/
.pagination {
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.pagination li {
  margin-right: 5px;
}
.pagination li .page-link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  border-radius: 50%;
  display: block;
  color: var(--secondary_color);
  background-color: rgba(35, 61, 99, 0.1);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;
  text-align: center;
}
.pagination li .page-link:hover,
.pagination li .page-link.current {
  background-color: var(--primary_color);
  color: var(--white_color);
}
.pagination li:last-child {
  margin-right: 0;
}
.pagination li:last-child .page-link,
.pagination li:first-child .page-link {
  line-height: 45px;
}

/*
        PreLoader
*/
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--white_color);
  top: 0;
  left: 0;
  z-index: 99999;
}
.loader-wrapper .loader-ripple {
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader-wrapper .loader-ripple > * {
  position: absolute;
  border: 6px solid var(--primary_color);
  opacity: 1;
  border-radius: 50%;
  animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  transform: scale(0);
  width: 100%;
  height: 100%;
}
.loader-wrapper .loader-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loader-ripple {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/*
        Modal Video
*/
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}
.modal-video .modal-video-movie-wrap {
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: relative;
}
.modal-video .modal-video-movie-wrap iframe {
  width: 700px;
  height: 450px;
}
.modal-video .modal-video-movie-wrap .modal-video-close-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 42px;
  color: var(--secondary_color);
  font-weight: 500;
  transition: 0.3s;
  z-index: 1;
}
.modal-video .modal-video-movie-wrap .modal-video-close-btn:hover {
  background: var(--primary_color);
  color: var(--white_color);
}
.modal-video .modal-video-movie-wrap .modal-video-close-btn:after {
  content: "X";
}
.modal-video .modal-video-movie-wrap .modal-video-close-btn::before {
  content: "";
  width: 40px;
  height: 40px;
  background: var(--white_color);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  z-index: -1;
  animation: scaling 1s infinite linear;
}

@keyframes scaling {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

/*
        Button
*/
.theme-button {
  /* font-family: "Barlow", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--white_color);
  line-height: 50px;
  padding: 0 40px 0 30px;
  background-color: var(--secondary_color);
  /* background-color: #C39239; */
  border-radius: 4px;
  text-transform: capitalize;
  transition: all 0.3s;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  position: relative;
  display: inline-block;
}
.theme-button:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
  text-decoration: none;
}
.theme-button .icon {
  position: absolute;
  top: 51%;
  right: 24px;
  font-size: 14px;
  transition: all 0.3s;
  transform: translateY(-50%);
}
.theme-button:hover .icon {
  right: 22px;
}
/* Simple Button */
.simple-button {
  position: relative;
  color: var(--secondary_color);
  text-transform: capitalize;
  transition: all 0.3s;
  font-weight: 500;
  font-size: 16px;
  padding-right: 15px;
}
.simple-button:hover {
  color: var(--primary_color);
}
.simple-button .icon {
  position: absolute;
  top: 4px;
  right: 0;
  font-size: 14px;
  transition: all 0.3s;
}
.simple-button:hover .icon {
  right: -3px;
}

/* ---------------------------------------------------
            Divider
--------------------------------------------------- */
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider .divider__dot {
  width: 10px;
  height: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 0 15px;
  background-color: var(--secondary_color);
}

/* ---------------------------------------------------
            Section Heading
--------------------------------------------------- */
.sec-heading .sec__title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 23px;
  color: var(--secondary_color);
  line-height: 55px;
}
.sec-heading .sec__meta {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: var(--paragraph_color);
  font-weight: 600;
}

/* ----------------------------------------------------
				ProgressBar
----------------------------------------------------- */
.progressbar-wrap {
  margin-top: 37px;
}
.progressbar-wrap .progressbar-item > * {
  border-radius: 30px;
}
.progressbar-wrap .progressbar-item {
  margin-bottom: 16px;
}
.progressbar-wrap .progressbar-item:last-child {
  margin-bottom: 0;
}

/* ----------------------------------------------------
				HEADER
----------------------------------------------------- */
.header-area {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1010;
}
.header-area .header-bar-wrapper {
  position: relative;
  background-color: var(--secondary_color);
  padding: 10px 0;
}
.header-area .header-bar-wrapper .header-bar-grid {
  display: grid;
  /* grid-template-columns: repeat( minmax(570px, 1fr)); */
  grid-column-gap: 30px;
  align-items: center;
  justify-items: end;
}

.header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul li,
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-social
  li {
  font-size: 16px;
  color: var(--white_color);
  margin-right: 15px;
  position: relative;
  font-weight: 500;
  align-items: center;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-left
  ul
  li:last-child {
  margin-right: 0;
}
.header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul li .icon {
  margin-right: 7px;
}
.header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul li a {
  color: var(--white_color);
  font-size: 16px;
  display: inline-block;
  transition: all 0.3s;
  line-height: 16px;
  padding-bottom: 3px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-left
  ul
  li
  a:hover,
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-social
  li
  a:hover,
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  > a:hover {
  color: var(--primary_color);
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex {
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-social {
  align-items: center;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-social
  li
  a {
  color: var(--white_color);
  transition: 0.3s;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language {
  margin-left: 5px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li {
  position: relative;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  > a {
  align-items: center;
  color: var(--white_color);
  font-size: 16px;
  transition: all 0.3s;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  > a
  svg {
  margin-top: 4px;
  margin-left: 4px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 4px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li:hover
  ul {
  top: 40px;
  opacity: 1;
  visibility: visible;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  ul {
  position: absolute;
  top: 60px;
  right: 0;
  width: 150px;
  background-color: var(--white_color);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  transition: all 0.3s;
  text-align: left;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  ul:before {
  position: absolute;
  content: "";
  left: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--white_color);
  bottom: 100%;
  transform: translateX(-50%);
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  ul
  li:first-child
  a {
  padding-top: 15px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-language
  li
  ul
  li
  a {
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  color: var(--secondary_color);
  text-transform: capitalize;
  transition: all 0.3s;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-cta-btn {
  margin-left: 20px;
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-cta-btn
  a {
  background: var(--primary_color);
}
.header-area
  .header-bar-wrapper
  .header-bar-grid
  .header-bar-right
  .header-bar-right-flex
  .header-bar-cta-btn
  a:hover {
  background: var(--white_color);
  color: var(--primary_color);
}
.header-area .header-menu-wrapper {
  position: relative;
  background: linear-gradient(var(--white_color) 93%, transparent 5%);
  z-index: 1;
  width: 100%;
}
.header-menu-fixed .header-area .header-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: var(--white_color);
  transition: all 0.5s;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.header-area .header-menu-wrapper .header-menu-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-area .header-menu-wrapper .header-menu-flex .header-menu-left a img {
  margin-bottom: -7px;
}
.header-area .header-menu-wrapper .header-menu-flex .header-menu-right {
  display: flex;
  align-items: center;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  > ul {
  display: flex;
  align-items: center;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item {
  position: absolute;
  top: 80px;
  left: 0;
  width: 200px;
  background-color: var(--white_color);
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  transform: scale(0.9);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item:after {
  position: absolute;
  content: "";
  left: 25px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--white_color);
  bottom: 100%;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  /* background-image: url(../images/dots3.png); */
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  > li:hover
  > .dropdown-menu-item {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  top: 100%;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li {
  display: block;
  padding-top: 0;
  padding-right: 0;
  position: relative;
  text-transform: capitalize;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li
  a {
  color: var(--paragraph_color);
  display: block;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 4px 15px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 4px;
  font-size: 15px;
  position: relative;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li
  a
  .icon {
  position: absolute;
  right: 0;
  top: 9px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li
  ul {
  right: -90%;
  left: auto;
  top: 0px;
  transform: scale(1);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li:hover
  > ul {
  top: 0;
  right: -100%;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li
  ul:after {
  display: none;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  a.active {
  color: var(--primary_color);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li {
  list-style: none;
  display: inline-block;
  text-transform: capitalize;
  padding-right: 30px;
  position: relative;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li:last-child {
  padding-right: 0;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  ul
  li
  a:after {
  position: absolute;
  width: 7px;
  height: 7px;
  content: "";
  border-radius: 50%;
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%) scale(0);
  left: 3px;
  transition: all 0.3s;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  ul
  li
  a:hover:after {
  background-color: var(--primary_color);
  transform: translateY(-50%) scale(1);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  a {
  color: var(--secondary_color);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  > ul
  > li
  > a {
  line-height: 85px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  ul
  li
  a:hover {
  padding-left: 18px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  a:hover,
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .cart__icon
  a:hover,
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .search-button:hover,
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  .dropdown-menu-item
  li
  a:hover,
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items
  .item__info
  a:hover {
  color: var(--primary_color);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  a
  .menu-icon {
  margin-left: 3px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items {
  text-align: left;
  background-color: var(--white_color);
  position: absolute;
  right: -25px;
  top: 80px;
  width: 300px;
  padding: 20px 20px;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items.moreproducts {
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li:hover
  .cart__items {
  top: 50px;
  opacity: 1;
  visibility: visible;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items:after {
  position: absolute;
  content: "";
  right: 25px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--white_color);
  bottom: 100%;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/dots3.png);
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items {
  border-bottom: 1px solid var(--gray_color);
  padding-bottom: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 18px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items:first-child {
  margin-top: 0;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items:last-child {
  margin-bottom: 0;
  border: none;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items
  .item__info
  a {
  display: block;
  color: var(--secondary_color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-right: 30px;
  transition: all 0.3s;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items
  .item__info
  span {
  font-weight: 600;
  font-size: 16px;
  color: var(--paragraph_color);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items
  .item__info
  del {
  font-weight: 600;
  color: #a0aec6;
  margin-right: 2px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .items
  img {
  width: 50px;
  margin-right: 10px;
  border-radius: 4px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .cart__info
  p {
  margin-top: 15px;
  margin-bottom: 15px;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 600;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .cart__info
  p
  span {
  color: var(--secondary_color);
  font-weight: 700;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .cart__info
  a {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  .cart__items
  .cart__info
  a.check__btn {
  margin-left: 8px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex {
  position: relative;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul,
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex {
  display: flex;
  align-items: center;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li {
  padding: 3px 0 0 30px;
  position: relative;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .cart__icon {
  position: relative;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .cart__icon
  a {
  color: var(--secondary_color);
  font-size: 16px;
  transition: 0.3s;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .cart__icon
  .cart__item-count {
  position: absolute;
  background-color: var(--primary_color);
  color: var(--white_color);
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  font-weight: 500;
  border-radius: 50%;
  font-size: 12px;
  right: -8px;
  top: -5px;
  padding-left: 1px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .item__remove {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 700;
  font-size: 17px;
  color: var(--secondary_color);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .item__remove:hover {
  color: var(--primary_color);
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-cart
  ul
  li
  .search-button {
  color: var(--secondary_color);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  transition: 0.3s;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform {
  position: absolute;
  right: 0;
  top: 80px;
  width: 330px;
  border: 5px solid var(--gray_color);
  border-radius: 8px;
  background: var(--white_color);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform.active {
  opacity: 1;
  visibility: visible;
  top: 55px;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform
  form {
  display: flex;
  align-items: center;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform
  form
  input {
  width: 100%;
  background-color: var(--white_color);
  padding: 15px 51px 15px 20px;
  border: 1px solid var(--dark_gray);
  font-weight: 500;
  font-size: 14px;
  resize: none;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  margin: 0;
  line-height: initial;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform
  form
  .form-icon {
  right: 0;
  background: var(--gray_color);
  height: 100%;
  line-height: 49px;
  width: 50px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 9;
}
.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  .header-menu-right-flex
  .header-menu-searchform
  form
  .form-icon:hover {
  background: var(--primary_color);
  color: var(--white_color);
}
/* Header Style 2 */
.header-style2 .header-bar-wrapper {
  border-radius: 8px 8px 0 0;
  margin-top: 30px;
}
.header-style2 .header-bar-wrapper,
.header-style2 .header-menu-wrapper {
  margin-right: 60px;
  margin-left: 60px;
  transition: 0.3s;
}
.header-style2 .header-menu-wrapper {
  border-radius: 0 0 8px 8px;
  background: var(--white_color);
}
.header-menu-fixed .header-style2 .header-bar-wrapper,
.header-menu-fixed .header-style2 .header-menu-wrapper {
  margin: 0;
  border-radius: 0;
}

/* Side menu */
.side-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: var(--secondary_color);
  overflow-x: hidden;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transform: translateX(-100%);
}
.side-nav-container.active {
  transform: translateX(0);
}
.side-nav-container .humburger-menu .humburger-menu-lines {
  display: inline-block;
  text-align: right;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 18px;
  opacity: 0.5;
}
.side-nav-container .humburger-menu .humburger-menu-lines:before,
.side-nav-container .humburger-menu .humburger-menu-lines:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 10px;
  left: 0;
  background-color: var(--white_color);
  transform: rotate(-43deg);
  transition: all 0.3s;
}
.side-nav-container .humburger-menu .humburger-menu-lines:after {
  transform: rotate(43deg);
}
.side-nav-container .side-menu-wrap {
  margin-top: 100px;
}
.side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item {
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
}
.side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item a {
  font-size: 16px;
  color: var(--white_color);
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  padding: 14px 20px;
  transition: all 0.3s;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 54px;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.3s;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:before,
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:after {
  position: absolute;
  top: 24px;
  right: 20px;
  content: "";
  width: 11px;
  height: 2px;
  background-color: var(--white_color);
  transform: rotate(-90deg);
  transition: all 0.3s;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:after {
  transform: rotate(0);
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu {
  display: block;
  height: 0;
  opacity: 0;
  transition: 0.3s;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item.active
  .side-sub-menu {
  height: auto;
  opacity: 1;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item a {
  font-size: 16px;
  color: var(--white_color);
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  padding: 14px 20px;
  transition: all 0.3s;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li
  a {
  padding-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  opacity: 0.8;
}
.side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li
  ul {
  padding-left: 20px;
}
.side-menu-open {
  width: 27px;
  cursor: pointer;
  border-radius: 5px;
  display: none;
  right: 0;
  top: 16px;
  margin-left: 20px;
}
.side-menu-open .menu__bar {
  height: 2px;
  background-color: var(--secondary_color);
  display: block;
  transition: all 0.3s;
}
.side-menu-open .menu__bar:nth-child(1),
.side-menu-open .menu__bar:nth-child(2) {
  margin-bottom: 5px;
}
.side-menu-open .menu__bar:nth-child(2) {
  margin-left: 5px;
}
.side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item a {
  font-size: 16px;
  color: var(--white_color);
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  padding: 14px 20px;
  transition: all 0.3s;
}
.side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item2 a {
  background-color: rgba(255, 255, 255, 0.12);
}

/* ----------------------------------------------
          Breadcrumb
---------------------------------------------- */
.breadcrumb-wrapper {
  background: var(--gray_color) url(../images/breadcrumb-bg2.jpg) center/cover
    no-repeat fixed;
  padding: 252px 0 0 0;
  position: relative;
  z-index: 1;
}
.breadcrumb-wrapper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary_color);
  opacity: 0.9;
  z-index: -1;
}
.breadcrumb-wrapper .breadcrumb-content {
  padding: 0 0 115px 0;
  position: relative;
}
.breadcrumb-wrapper .breadcrumb-content h2 {
  font-size: 47px;
  text-transform: capitalize;
  font-weight: 700;
  text-align: center;
  color: var(--white_color);
  line-height: 1.1;
}
.breadcrumb-wrapper .breadcrumb-content ul {
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: var(--white_color);
  padding: 10px 32px;
  transform: translateX(-50%);
  border-radius: 30px;
  margin-bottom: -22px;
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  display: flex;
  align-items: center;
}
.breadcrumb-wrapper .breadcrumb-content ul li {
  text-transform: capitalize;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.breadcrumb-wrapper .breadcrumb-content ul li a {
  color: var(--secondary_color);
  transition: all 0.3s;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding-right: 22px;
  position: relative;
}
.breadcrumb-wrapper .breadcrumb-content ul li a:hover {
  color: var(--primary_color);
}
.breadcrumb-wrapper .breadcrumb-content ul li a:after {
  content: ".";
  position: absolute;
  right: 7px;
  font-size: 28px;
  line-height: 10px;
}

/* ----------------------------------------------
          Hero Slider
---------------------------------------------- */
.hero-slider-wrapper {
  position: relative;
}
.hero-slider-wrapper .hero-slider-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: relative;
  padding-top: 138px;
  /* filter: grayscale(100%); */
}
.hero-slider-wrapper .hero-slider-item:before {
  content: "";
  background: rgba(3, 49, 50, 0.6);
  /* background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)); */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.hero-slider-wrapper .hero-slider-item .hero-slider-content {
  transition: 1s;
  opacity: 0;
  transform: scale(0.2);
}
.hero-slider-wrapper .owl-item.active .hero-slider-item .hero-slider-content {
  opacity: 1;
  transform: scale(1);
}
.hero-slider-wrapper .hero-slider-item .hero-slider-content h1 {
  color: var(--white_color);
  margin: 0 0px 20px 0px;
  text-align: inherit;
  line-height: 76px;
  padding: 0;
  font-weight: 700;
  font-size: 65px;
  transition: 1s;
}
.hero-slider-wrapper .hero-slider-item .hero-slider-content p {
  margin: 0px 0px 38px 0px;
  text-align: inherit;
  line-height: 30px;
  font-weight: 500;
  font-size: 20px;
  color: var(--white_color);
  transition: 1s;
}
.hero-slider-wrapper .offset-2.text-center .hero-slider-btns {
  justify-content: center;
}
.hero-slider-wrapper .hero-slider-item .hero-slider-content .hero-slider-btns  {
  display: flex;
  align-items: center;
  transition: 1s;
  flex-wrap: wrap;
}
.hero-slider-wrapper
  .hero-slider-item
  .hero-slider-content
  .hero-slider-btns
  a {
  background: var(--white_color);
  color: var(--secondary_color);
  font-weight: 600;
}
.hero-slider-wrapper
  .hero-slider-item
  .hero-slider-content
  .hero-slider-btns
  a:hover {
  background: var(--primary_color);
  color: var(--white_color);
}
.hero-slider-wrapper
  .hero-slider-item
  .hero-slider-content
  .hero-slider-btns
  a:first-child {
  margin-right: 10px;
}
.hero-slider-wrapper .owl-nav {
  right: 60px;
  position: absolute;
  bottom: 60px;
}

.hero-slider-wrapper .owl-nav > * {
  width: 50px;
  height: 50px;
  border: none;
  line-height: 50px;
  border-radius: 50% !important;
  background: var(--white_color) !important;
  color: var(--secondary_color) !important;
  font-size: 25px !important;
  padding: 0 !important;
  transition: 0.3s;
}
.hero-slider-wrapper .owl-nav > *:hover {
  background: var(--primary_color) !important;
  color: var(--white_color) !important;
}
.hero-slider-wrapper .owl-nav > * > * {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 48px;
}
/* Hero Slider 2 */
.hero-slider-2 .hero-slider-item {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
  z-index: 9;
  padding-top: 167px;
}
.hero-slider-2 .hero-slider-item.bg-2 {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
}
.hero-slider-2 .hero-slider-item.bg-3 {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
}

.hero-slider-wrapper .hero-slider-item.bg-7 {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
}
.hero-slider-wrapper .hero-slider-item.bg-8 {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
}
.hero-slider-wrapper .hero-slider-item.bg-9 {
  background: url(../images/shop-slider1.jpg) center/cover no-repeat;
}
.hero-slider-2 .owl-nav {
  bottom: auto;
  top: 55.4%;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}
.hero-slider-2 .owl-nav .owl-prev {
  right: auto;
  margin-left: 60px;
}
.hero-slider-2 .owl-nav .owl-next {
  margin-right: 60px;
}
.hero-slider-2
  .hero-slider-item
  .hero-slider-content
  .hero-slider-btns
  a:last-child,
.shop-hero-slider
  .hero-slider-item
  .hero-slider-content
  .hero-slider-btns
  a:last-child {
  background: var(--primary_color);
  color: var(--white_color);
}

/* ----------------------------------------------
          Shop
---------------------------------------------- */
/* Shop Category collection */
.shop-category-collection-wrapper {
  padding: 112px 0 112px 0;
}
.shop-category-collection-wrapper .sec__title,
.shop-best-products-wrapper .sec__title {
  margin-bottom: 50px;
}
.shop-category-collection-grid {
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-column-gap: 30px;
}
.shop-category-collection-grid .shop-category-collection-item {
  position: relative;
}
.shop-category-collection-grid .shop-category-collection-item img {
  width: 100%;
  border-radius: 2px;
  margin: 0 0 -7px;
}
.shop-category-collection-grid .shop-category-collection-item .details {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}
.shop-category-collection-grid .shop-category-collection-item .details h2 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 20px 0;
  line-height: 1.2;
}
.shop-category-collection-grid .shop-category-collection-item .details h2 a {
  transition: all 0.3s;
  color: var(--secondary_color);
}
.shop-category-collection-grid
  .shop-category-collection-item
  .details
  h2
  a:hover {
  color: var(--primary_color);
}

/* Shop Best Products */
.shop-best-products-wrapper {
  padding: 0 0 120px 0;
}
.shop-best-products-wrapper.best-seller {
  padding-top: 112px;
}
.shop-best-products-wrapper .loadmore {
  margin: 45px 0 0 0;
}
.shop-best-products-wrapper .loadmore .theme-button {
  padding: 0 30px 0 45px;
}
.shop-best-products-wrapper .loadmore .theme-button .icon {
  right: auto;
  left: 22px;
}
.products {
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 30px;
  align-items: flex-start;
}
.products .product-item {
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
}
.products .product-item:hover {
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.products .product-item .product-img {
  position: relative;
}
.products .product-item .product-img .onsale {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: var(--secondary_color);
  color: var(--white_color);
  font-size: 15px;
  font-weight: 700;
  border-radius: 50%;
}
.products .product-item .product-img img {
  width: 100%;
}
.products .product-item .product-img .addto-cart-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.products .product-item .product-img .addto-cart-btn .theme-button {
  border-radius: 30px;
}
.products .product-item:hover .product-img .addto-cart-btn {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}
.products .product-item .product-content {
  padding: 24px 10px;
}
.products .product-item .product-content h3 {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: capitalize;
}
.products .product-item .product-content h3 a {
  color: var(--secondary_color);
  transition: all 0.3s;
}
.products .product-item .product-content h3 a:hover,
.products .product-item .product-content .product-rating li {
  color: var(--primary_color);
}
.products .product-item .product-content .product-rating {
  align-items: center;
  justify-content: center;
  margin: -3px 0 8px 0;
}
.products .product-item .product-content .product-rating li {
  margin: 0 1px;
}
.products .product-item .product-content .prices .ins {
  color: var(--primary_color);
}
.products .product-item .product-content .prices .del {
  color: var(--paragraph_color);
  text-decoration: line-through;
  opacity: 0.7;
  margin-right: 5px;
}
.products .product-item .product-content .prices > * {
  font-size: 18px;
  font-weight: 700;
}
.products .product-item .product-action-list {
  position: absolute;
  top: 15px;
  left: -60px;
  background-color: var(--white_color);
  transition: all 0.3s;
}
.products .product-item:hover .product-action-list {
  left: 0;
}
.products .product-item .product-action-list li {
  font-size: 18px;
  border-bottom: 1px solid var(--gray_color);
  transition: all 0.3s;
}
.products .product-item .product-action-list li a {
  color: var(--secondary_color);
  padding: 0 13px;
  height: 44px;
  display: block;
  transition: all 0.3s;
  line-height: 50px;
}
.products .product-item .product-action-list li a:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
}

/* Shop Featured Product */
.shop-featured-product-wrapper {
  background-color: #f6f8f9;
  padding: 120px 0 120px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.shop-featured-product-wrapper:before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 42%;
  height: 88%;
  background-image: url(../images/dots3.png);
  background-position: center;
  z-index: -1;
  opacity: 0.1;
  transform: rotate(-13deg);
}
.shop-featured-product-wrapper .container {
  padding: 0;
}
.shop-featured-product-wrapper .shop-featured-product-grid {
  grid-template-columns: repeat(auto-fit, minmax(585px, 1fr));
  grid-column-gap: 30px;
  align-items: flex-start;
}
.shop-featured-product-wrapper .shop-featured-product-grid img {
  width: 100%;
  margin: 50px 0 0 0;
}
.shop-featured-product-wrapper .shop-featured-product-grid h1,
.shop-featured-product-wrapper
  .shop-featured-product-grid
  .shop-featured-left-mdl-grid
  h2 {
  font-size: 45px;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 23px 0;
  color: var(--secondary_color);
}
.shop-featured-product-wrapper .shop-featured-product-grid p {
  color: var(--paragraph_color);
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin: 0 0 30px 0;
}
.shop-featured-product-wrapper
  .shop-featured-product-grid
  .shop-featured-left-mdl-grid {
  grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
  grid-column-gap: 30px;
}
.shop-featured-product-wrapper
  .shop-featured-product-grid
  .shop-featured-left-mdl-grid
  h2 {
  font-size: 18px;
  margin-bottom: 15px;
}
/* Shop Sidebar */
.shop-sidebar .shop-widget {
  position: relative;
  margin: 0 0 40px 0;
}
.shop-sidebar .shop-widget:last-child {
  margin-bottom: 0;
}
.shop-sidebar .shop-widget h2,
.shop-sidebar .shop-widget h3 {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--secondary_color);
  margin: 0 0 20px 0;
  line-height: 1.2;
}
.shop-sidebar .widget_categories ul li {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--paragraph_color);
  border-bottom: 1px solid var(--gray_color);
}
.shop-sidebar .widget_categories ul li:last-child {
  border: none;
}
.shop-sidebar .widget_categories ul li a {
  margin: 0 5px 0 0;
  padding: 0;
  border: 0;
  padding: 10px 0 13px 25px;
}
.shop-sidebar .widget_categories ul li a:hover,
.widget_feature_products ul li .details h3 a:hover,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating
  a:hover,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-tags
  p
  a:hover {
  background: none;
  color: var(--primary_color);
}
.shop-sidebar .widget_categories ul li a .icon {
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 15px;
  transition: all 0.3s;
}
.shop-sidebar .widget_categories ul li a:hover .icon {
  left: 5px;
}
.widget_product_color ul li {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}
.widget_product_color ul li:last-child {
  margin-right: 0;
}
.widget_product_color ul li a {
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--secondary_color);
  border-radius: 50%;
}
.widget_product_color ul li.orange a,
p .orange {
  background-color: var(--primary_color);
}
.widget_product_color ul li.blue a,
p .blue {
  background-color: blue;
}
.widget_product_color ul li.darkgray a,
p .darkgray {
  background-color: var(--paragraph_color);
}
.widget_feature_products > ul > li {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray_color);
  margin: 0 0 25px 0;
  padding: 0 0 25px 0;
}
.widget_feature_products > ul > li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.widget_feature_products ul li .img {
  margin-right: 15px;
}
.widget_feature_products ul li .img img {
  width: 85px;
  border-radius: 8px;
  margin-bottom: -7px;
}
.widget_feature_products ul li .details h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 3px 0;
}
.widget_feature_products ul li .details h3 a {
  color: var(--secondary_color);
  transition: all 0.3s;
}
.widget_feature_products ul li .details ul {
  align-items: center;
}
.widget_feature_products ul li .details ul li {
  color: var(--primary_color);
  margin: 0 2px 0 0;
  font-size: 13px;
  line-height: 25px;
}
.widget_feature_products ul li .details .amount ins {
  text-decoration: none;
}
.widget_feature_products ul li .details .amount {
  font-size: 16px;
  color: var(--primary_color);
  font-weight: 700;
}
.widget_feature_products ul li .details .amount del {
  color: var(--paragraph_color);
  margin-right: 5px;
  text-decoration: line-through;
  opacity: 0.7;
}

/* shop sidebar */
.shop-sidebar-wrapper {
  padding: 120px 0 40px 0;
}
.shop-sidebar-wrapper .pagination {
  margin: 45px 0 0 0;
}
.shop-sidebar-grid .shop-sidebar-product-ordering {
  align-items: center;
  justify-content: space-between;
}
.shop-sidebar-grid .shop-sidebar-product-ordering .ordering-left {
  position: relative;
}
.shop-sidebar-grid .shop-sidebar-product-ordering .ordering-left .icon {
  position: absolute;
  z-index: 1;
  color: var(--paragraph_color);
  right: 15px;
  top: 15px;
}
select {
  background-color: var(--white_color);
  border-radius: 4px;
  border: solid 1px var(--dark_gray);
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  height: auto;
  outline: 0;
  padding: 12px 30px 12px 18px;
  position: relative;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-text-overflow: "";
  text-overflow: "";
}
.shop-sidebar-grid .shop-sidebar-product-ordering p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
}
.shop-sidebar-grid .products {
  grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
  margin: 45px 0 0 0;
}

/* Shop Product Details */
.product-details-item-wrapper {
  padding: 120px 0 0 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-left
  img {
  width: 100%;
  border-radius: 4px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  h1 {
  font-size: 30px;
  color: var(--secondary_color);
  font-weight: 700;
  margin: 0 0 19px 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating {
  align-items: center;
  font-size: 14px;
  line-height: 18px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating
  ul {
  margin: 0 5px 0 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating
  ul
  li {
  color: var(--primary_color);
  margin: 0 1px 0 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating
  a {
  color: var(--paragraph_color);
  text-transform: capitalize;
  transition: all 0.3s;
  font-weight: 500;
  font-size: 14px;
  margin: 0 5px 0 5px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-review-rating
  a:last-child {
  margin-right: 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .amount {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary_color);
  margin: 20px 0 24px 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .amount
  del {
  color: var(--paragraph_color);
  opacity: 0.6;
  font-size: 23px;
  text-decoration: line-through;
  font-weight: 600;
  margin-right: 5px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .amount
  ins {
  text-decoration: none;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  p {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 23px 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .stock {
  font-size: 16px;
  color: #a1a8b4;
  font-weight: 600;
  display: inline-block;
  margin: 0 0 18px 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .stock
  strong {
  color: var(--secondary_color);
  font-weight: 600;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-color
  p,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-quantity-flex
  form
  label,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-tags
  p,
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-share-links {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--secondary_color);
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-color
  p
  span {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  margin: 1px 0 0 10px;
  cursor: pointer;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .product-details-color
  p
  span.black {
  background-color: var(--secondary_color);
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size {
  align-items: center;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size
  .size-select {
  position: relative;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size
  .size-select
  .icon {
  position: absolute;
  z-index: 1;
  right: 15px;
  color: var(--paragraph_color);
  top: 12px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size
  .size-select {
  width: 72px;
  left: 10px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-size
  .size-select
  select {
  width: 100%;
  padding: 9px 30px 9px 18px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-quantity-flex {
  align-items: center;
  margin: 26px 0 20px 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-quantity-flex
  .product-details-quantity
  .product-details-quantity {
  display: flex;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-quantity-flex
  .product-details-quantity
  .product-details-quantity
  label {
  font-weight: 600;
}
.product-details-quantity div {
  position: relative;
  align-items: center;
}
.product-details-quantity div .btn {
  margin: 0 5px 0 5px;
  width: 101px;
  height: 50px;
  line-height: 47px;
  border-radius: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 0 10px;
  border: 1px solid var(--dark_gray);
}
.product-details-quantity label {
  margin-right: 10px;
}
.product-details-quantity .button {
  width: 50px;
  height: 50px;
  line-height: 47px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  transition: all 0.3s;
  cursor: pointer;
  border: 1px solid var(--dark_gray);
  font-weight: 500;
  background: var(--white_color);
}
.product-details-quantity .button.inc {
  margin-right: 5px;
}
.product-details-quantity .button:hover {
  background: var(--primary_color);
  color: var(--white_color);
  border-color: var(--primary_color);
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-quantity-flex
  .theme-button {
  margin: 0;
  border-radius: 30px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .sku {
  font-size: 15px;
  margin-bottom: 12px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-right
  .product-details-item-content
  .sku
  strong {
  color: var(--secondary_color);
  margin-right: 5px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-tags
  p {
  margin-bottom: 15px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-tags
  p
  a {
  color: var(--paragraph_color);
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 7px;
  display: inline-block;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-tags
  p
  a:first-child {
  margin-left: 10px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-share-links {
  margin: 20px 0 0 0;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-share-links
  ul
  li:first-child {
  margin-left: 10px;
}
.product-details-item-wrapper
  .product-details-item
  .product-details-item-content
  .product-details-share-links
  ul
  li {
  margin: 0 0 0 5px;
}

/* Shop Product Details Tab */
.product-details-tabs-wrapper {
  padding: 120px 0 120px 0;
}
.product-details-tabs-wrapper .product-tabs-width {
  max-width: 910px;
  margin: auto;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs
  li {
  background-color: var(--secondary_color);
  color: var(--white_color);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  padding: 12px 30px;
  margin-right: 5px;
  cursor: pointer;
  border: none;
  line-height: 25px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs
  li:last-child {
  margin-right: 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs
  li:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs
  li.react-tabs__tab--selected {
  background-color: var(--primary_color);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .product-details-tabs
  li.react-tabs__tab--selected:after {
  border-top: 10px solid var(--primary_color);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel {
  background-color: var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 22px 25px;
  border-radius: 4px;
  display: none;
  visibility: hidden;
  margin: 52px 0 0 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  display: block;
  visibility: visible;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  h1 {
  font-size: 28px;
  color: var(--secondary_color);
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 20px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 15px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  p:last-child {
  margin-bottom: 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  .table
  h3 {
  font-size: 20px;
  color: var(--secondary_color);
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  table {
  width: 100%;
  margin-bottom: 40px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  .table:last-child
  table {
  margin-bottom: 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  table
  tr {
  margin: 0;
  padding: 0;
  border: 1px solid var(--dark_gray);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  table
  tr
  td {
  border: 1px solid var(--dark_gray);
  margin: 0;
  padding: 10px 20px;
  width: 230px;
  font-weight: 500;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .react-tabs__tab-panel
  table
  tr
  td:last-child {
  width: 500px;
  color: var(--paragraph_color);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-lists {
  margin: 43px 0 70px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  > ul
  > li {
  align-items: flex-start;
  margin: 0 0 30px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .image {
  margin-right: 20px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .image
  img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 8px solid var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  h3 {
  font-size: 20px;
  text-transform: capitalize;
  color: var(--secondary_color);
  font-weight: 600;
  margin: 0 0 7px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  h3
  span {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  margin: 0 0 0 15px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .rating {
  align-items: center;
  margin-bottom: 2px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .rating
  li {
  color: var(--primary_color);
  font-size: 14px;
  margin-right: 2px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .rating
  li:last-child {
  margin-right: 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  p {
  font-size: 16px;
  color: var(--paragraph_color);
  margin: 0 0 23px 0;
  font-weight: 500;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .helpful-btns
  p {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin: 0;
  align-items: center;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .helpful-btns
  p
  a {
  border: 1px solid rgba(128, 137, 150, 0.4);
  border-radius: 4px;
  padding: 4px 12px;
  color: var(--paragraph_color);
  margin: 0 3px 0 5px;
  transition: all 0.3s;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .helpful-btns
  p
  a
  > * {
  margin-right: 3px;
  color: var(--primary_color);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  ul
  li
  .details
  .helpful-btns
  p
  a:hover {
  background-color: rgba(128, 137, 150, 0.1);
  color: var(--secondary_color);
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields {
  padding: 70px 0 0 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  h2 {
  font-size: 28px;
  color: var(--secondary_color);
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .review-rating-field-grid {
  grid-template-columns: repeat(auto-fit, minmax(266px, 1fr));
  grid-column-gap: 30px;
  align-items: flex-start;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star {
  transform: rotateY(180deg);
  position: relative;
  min-height: 20px;
  justify-content: flex-end;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  input {
  display: none;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label {
  display: block;
  cursor: pointer;
  width: 30px;
  position: relative;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label:before,
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label:after {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(../images/stars.png);
  position: absolute;
  background-size: cover;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label:after {
  background-image: url(../images/stars.png);
  background-position: center bottom;
  opacity: 0;
  transition: 0.3s;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label:hover:after,
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  label:hover
  ~ label:after,
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  .ratings-review
  .ratings-star
  input:checked
  ~ label:after {
  opacity: 1;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  h4 {
  line-height: 15px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  h4,
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  label {
  font-size: 14px;
  color: var(--secondary_color);
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  h4 {
  margin: 0 0 9px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  h4
  sup,
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  label
  sup {
  position: absolute;
  right: -8px;
  top: -5px;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  label {
  margin: 0 0 8px 0;
}
.product-details-tabs-wrapper
  .product-tabs-width
  .react-tabs
  .reviews
  .review-fields
  form
  textarea {
  height: 150px;
}

/* Shop Related Products */
.related-products-wrapper {
  padding: 112px 0 50px 0;
}
.related-products-wrapper .sec-heading .sec__title {
  margin-bottom: 50px;
}

/* Shop  Product Cart */
.product-cart-wrapper table {
  width: 100%;
}
.product-cart-wrapper table tr td {
  border: 1px solid #dee2e6;
  padding: 15px 20px;
  position: relative;
  border-spacing: 0;
  font-weight: 600;
  color: var(--secondary_color);
}
.product-cart-wrapper table tbody tr img {
  width: 100px;
}
.product-cart-wrapper table tbody tr td.product-description a {
  font-weight: 700;
  font-size: 16px;
  color: var(--secondary_color);
  transition: all 0.3s;
  line-height: 25px;
}
.product-cart-wrapper table tbody tr td.product-description a:hover {
  color: var(--primary_color);
}
.product-cart-wrapper table tbody tr td.product-description p {
  font-weight: 500;
  color: var(--paragraph_color);
  line-height: 25px;
  margin: 6px 0 0 0;
}
.product-cart-wrapper table tbody tr .product-details-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-cart-wrapper table tbody tr .product-details-quantity .btn {
  width: 101px;
  height: 35px;
  line-height: 35px;
  border-radius: 30px;
  color: var(--secondary_color);
  user-select: none;
  font-weight: 700;
  font-size: 22px;
  border: 1px solid #dddddd;
  margin: 0 6px;
  text-align: center;
}
.product-cart-wrapper table tbody tr .product-details-quantity .qty-btn {
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s;
  border: 1px solid #dddddd;
  cursor: pointer;
  font-size: 22px;
}
.product-cart-wrapper table tbody tr .product-details-quantity .qty-btn:hover,
.product-cart-wrapper table tbody tr td.product-remove a:hover {
  background: var(--primary_color);
  color: var(--white_color);
  border-color: var(--primary_color);
}
.product-cart-wrapper table tbody tr td.product-remove a {
  border: 1px solid #dddddd;
  width: 35px;
  height: 35px;
  line-height: 32px;
  color: var(--paragraph_color);
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 16px;
  display: inline-block;
  font-size: 16px;
}
.product-cart-wrapper .product-cart-table-foot {
  border: 1px solid #dee2e6;
  padding: 15px 20px;
  margin-left: 2px;
  margin-right: 2px;
  justify-content: space-between;
}
.product-cart-wrapper .product-cart-table-foot form {
  align-items: center;
}
.product-cart-wrapper .product-cart-table-foot form input {
  margin: 0 5px 0 0;
}
.product-cart-wrapper .product-cart-table-foot form button {
  width: 100%;
}
.product-cart-wrapper {
  padding: 120px 0 40px 0;
}
.empty-cart-items h1 {
  font-size: 30px;
  margin: 0 0 25px 0;
}
.product-cart-wrapper .product-cart-totals {
  padding: 0 0 120px 0;
}
.product-cart-wrapper .product-cart-totals .product-cart-totals-box {
  max-width: 370px;
  margin-left: auto;
  background-color: #f6f8f9;
  border-radius: 4px;
  padding: 30px 30px 33px 30px;
  margin: 35px 15px auto auto;
}
.product-cart-wrapper .product-cart-totals .product-cart-totals-box h1 {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 21px;
}
.product-cart-wrapper .product-cart-totals .product-cart-totals-box h2 {
  border-top: 1px solid #dee2e6;
  font-size: 16px;
  padding: 8px 0;
  line-height: inherit;
  color: var(--secondary_color);
  align-items: center;
  justify-content: space-between;
}
.product-cart-wrapper .product-cart-totals .product-cart-totals-box h2 strong {
  color: var(--paragraph_color);
  font-weight: 500;
}
.product-cart-wrapper
  .product-cart-totals
  .product-cart-totals-box
  .theme-button {
  margin-top: 24px;
}
.product-cart-wrapper .related-products-area {
  padding: 112px 0 0 0;
}

/* ----------------------------------------------
          Service
---------------------------------------------- */
.h-service {
  padding-top: 113px;
  padding-bottom: 120px;
}
.services-area {
  position: relative;
}
.services-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px;
  margin-top: 26px;
}
.h-service .service-button {
  margin-top: 80px;
}
.services-items-grid .service-items {
  position: relative;
  background-color: var(--white_color);
  border-radius: 20px 0 20px 0;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  padding: 45px 30px 40px 30px;
  z-index: 1;
  text-align: center;
}
.services-items-grid .service-items:hover {
  border-radius: 0;
}
.services-items-grid .service-items:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--primary_color);
  bottom: 0;
  left: 0;
  transform-origin: center;
  transition: 0.3s;
  transform: scale(0);
}
.services-items-grid .service-items:hover:after {
  transform: scale(1);
}
.services-items-grid .service-items:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/dots3.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: scale(0.6);
}
.services-items-grid .service-items:hover:before {
  opacity: 0.2;
  visibility: visible;
  transform: scale(1);
}
.services-items-grid .service-items:hover .service-items-num {
  transform: scale(1);
}
.services-items-grid .service-items .service-items-num {
  font-size: 35px;
  position: absolute;
  top: 3px;
  right: 5px;
  width: 60px;
  height: 55px;
  font-weight: 600;
  line-height: 55px;
  color: rgba(35, 61, 92, 0.3);
  border-radius: 0 0 0 10px;
  z-index: 1;
  transform: scale(0);
  transition: all 0.3s;
}
.services-items-grid .service-items .service-items-iconbox {
  color: var(--primary_color);
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(246, 107, 93, 0.1);
  border-radius: 50%;
  padding-top: 22px;
}
.services-items-grid .service-items:hover .service-items-iconbox {
  background: var(--primary_color);
  color: var(--white_color);
}
.services-items-grid .service-items .service-items-iconbox:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -8px;
  width: 50px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--white_color);
  transform: translateX(-50%);
}
.services-items-grid .service-items .service-items-iconbox .service-items-icon {
  font-size: 45px;
}
.services-items-grid .service-items .service-items-title {
  color: var(--secondary_color);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: capitalize;
  transition: 0.3s;
}
.services-items-grid .service-items:hover .service-items-title,
.service-slider-wrapper .service-slider-item .service-slider-title:hover {
  color: var(--primary_color);
}
.services-items-grid .service-items .service-items-description {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.services-items-grid .service-items .simple-button {
  margin-top: 19px;
  display: inline-block;
  line-height: 19px;
}

/* Service slider */
.service-slider-wrapper {
  position: relative;
  background-color: #f6f8f9;
}
.service-slider-wrapper .service-slider-grid {
  grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  grid-column-gap: 30px;
}
.service-slider-wrapper .service-slider-item {
  border: 2px solid var(--secondary_color);
  padding: 41px 30px 38px 30px;
  border-radius: 20px 0 20px 0;
  transition: all 0.3s;
}
.service-slider-wrapper .service-slider-item:hover {
  border-radius: 0 20px 0 20px;
}
.service-slider-wrapper .service-slider-item .service-slider-icon {
  font-size: 65px;
  color: var(--primary_color);
  display: inline-block;
  margin-bottom: 25px;
}
.service-slider-wrapper
  .owl-items:nth-child(3)
  .service-slider-item
  .service-slider-icon {
  font-size: 50px;
}
.service-slider-wrapper .service-slider-item .service-slider-title {
  color: var(--secondary_color);
  transition: all 0.3s;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
  text-transform: capitalize;
}
.service-slider-wrapper .service-slider-item .service-slider-desc {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 18px;
}
.service-slider-wrapper .owl-dots,
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 50px;
}
.service-slider-wrapper .owl-dots > * {
  width: 14px;
  height: 14px;
  border: 1px solid var(--secondary_color);
  border-radius: 50%;
  margin: 0 3px;
  background: none;
  transition: 0.3s;
}
.service-slider-wrapper .owl-dots .owl-dot span {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: none;
  transition: 0.3s;
}
.service-slider-wrapper .owl-dots .owl-dot:hover,
.service-slider-wrapper .owl-dots .owl-dot.active {
  border-color: var(--primary_color);
  background: var(--primary_color);
}
.service-slider-wrapper .owl-dots .owl-dot:hover span,
.service-slider-wrapper .owl-dots .owl-dot.active span {
  background: var(--primary_color);
}
.service-slider-wrapper .sec-heading .sec__title {
  margin-bottom: 25px;
}
.service-slider-wrapper .service-slider-right {
  padding-left: 30px;
}
.service-slider-wrapper .service-slider-right .desc {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 28px;
}
/* Service Video */
.service-video-wrapper {
  position: relative;
  z-index: 1;
  padding: 114px 0 113px 0;
}
.service-video-wrapper:before {
  content: "";
  background: #f6f8f9;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 50%;
  width: 100%;
}
.service-video-wrapper .service-content-box {
  position: relative;
  transition: 0.3s;
}
.service-video-wrapper .service-content-box:hover {
  transform: scale(0.99);
}
.service-video-wrapper .service-content-box:before,
.service-video-wrapper .service-content-box:after {
  position: absolute;
  content: "";
  bottom: -35px;
  left: -37px;
  width: 20%;
  height: 40%;
  background-image: url(../images/dots.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.2;
  transition: all 0.3s;
}
.service-video-wrapper .service-content-box:after {
  bottom: auto;
  top: -50px;
  right: -39px;
  left: auto;
  height: 84%;
  width: 18%;
}
.service-video-wrapper .service-content-box .service-img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.service-video-wrapper .service-content-box .service-video-button .video-btn {
  position: absolute;
  left: 40px;
  top: 0;
  width: 90px;
  color: var(--secondary_color);
  font-weight: 500;
  font-size: 22px;
  padding: 20px 36px 15px 36px;
  text-align: center;
  border-radius: 0 0 50px 50px;
  background-color: var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  overflow-wrap: break-word;
}
.service-video-wrapper
  .service-content-box
  .service-video-button
  .video-btn
  .icon {
  background-color: var(--secondary_color);
  width: 60px;
  height: 60px;
  line-height: 65px;
  display: inline-block;
  margin-top: 15px;
  border-radius: 50%;
  transition: all 0.3s;
  position: relative;
  color: var(--white_color);
  margin-left: -20px;
}
.service-video-wrapper
  .service-content-box
  .service-video-button
  .video-btn
  .icon:before {
  position: absolute;
  content: "";
  left: 1px;
  bottom: 1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.service-video-wrapper
  .service-content-box:hover
  .service-video-button
  .video-btn
  .icon {
  background: var(--primary_color);
}
/* Service 3 columns */
.service-3columns {
  background: var(--secondary_color);
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.service-3columns:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  background-image: url(../images/dots3.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.5;
}
.service-3columns .services-items-grid {
  margin-top: 7px;
}
/* service */
.service-main .h-service .sec-heading {
  max-width: 700px;
  margin: auto;
}
.service-main .service-slider-wrapper {
  padding-top: 120px;
}

/* Service Details */
.service-details-wrapper {
  padding: 120px 0 110px 0;
}
.service-content-wrap,
.service-content-wrap .item,
.service-content-wrap .service-content-details-slider {
  position: relative;
}
.service-details-wrapper .service-sidebar,
.service-details-wrapper {
  position: relative;
}
.service-details-wrapper .service-sidebar .service-widgets {
  position: relative;
  margin-bottom: 40px;
}
.service-details-wrapper .service-sidebar .service-widgets:last-child {
  margin-bottom: 0;
}
.service-details-wrapper .service-sidebar .service-widgets h2,
.service-details-wrapper .service-sidebar .service-widgets h3 {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--secondary_color);
  margin-bottom: 20px;
}
.widget_categories li {
  display: block;
}
.widget_categories li a {
  color: var(--secondary_color);
  font-size: 16px;
  position: relative;
  padding: 10px 20px;
  display: flex;
  border: 1px solid #a0aec6;
  font-weight: 600;
  transition: all 0.3s;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-radius: 10px 0 10px 0;
  align-items: center;
  justify-content: space-between;
}
.widget_categories li a:hover {
  padding-right: 16px;
  background: var(--primary_color);
  color: var(--white_color);
  border-radius: 4px;
  border-color: var(--primary_color);
}
.service-details-wrapper .service-sidebar .widget_contact {
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  border-radius: 20px;
  padding: 30px 23px 32px 23px;
  background-color: var(--white_color);
}
.service-details-wrapper .service-sidebar .widget_contact ul li {
  align-items: center;
  font-size: 16px;
  color: var(--secondary_color);
  position: relative;
  font-weight: 500;
  margin-bottom: 15px;
}
.service-details-wrapper .service-sidebar .widget_contact ul li:last-child {
  margin-bottom: 0;
}
.service-details-wrapper .service-sidebar .widget_contact ul li a {
  color: var(--secondary_color);
  transition: all 0.3s;
}
.service-details-wrapper .service-sidebar .widget_contact ul li .icon {
  width: 38px;
  height: 38px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  background: rgba(35, 61, 99, 0.1);
  margin-right: 10px;
  transition: all 0.3s;
}
.service-details-wrapper .service-sidebar .widget_contact ul li:hover .icon {
  background-color: var(--primary_color);
  color: var(--white_color);
}
.service-details-wrapper .service-sidebar .widget_contact ul li .icon:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -3px;
  transform: translateX(-50%);
  width: 22px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.service-details-wrapper .service-sidebar .widget_brochures {
  margin-bottom: 42px;
}
.service-details-wrapper .service-sidebar .widget_brochures p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
}
.service-details-wrapper .service-sidebar .widget_brochures .theme-button {
  width: 100%;
  margin-top: 43px;
}
.service-content-wrap .service-content-details-slider .owl-dots {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.service-content-wrap .service-content-details-slider .owl-dots > * {
  width: 14px;
  height: 14px;
  border: 2px solid var(--white_color);
  border-radius: 50%;
  margin: 0 1px;
  transition: all 0.3s;
  transform: scale(0.8);
  padding: 0;
  background: transparent;
}
.service-content-wrap .service-content-details-slider .owl-dots .act,
.service-content-wrap .service-content-details-slider .owl-dots .active {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  transform: scale(1);
}
.service-content-wrap .service-content-details-slider .owl-dots span {
  display: none;
}
.service-content-wrap .item:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--white_color);
  transform: translateX(-50%);
  z-index: 1;
}
.service-content-wrap .item img {
  border-radius: 4px 4px 20px 20px;
}
.service-content-wrap .service-content {
  padding: 32px 0 0 0;
}
.service-content-wrap .service-content h1 {
  font-size: 30px;
  color: var(--secondary_color);
  font-weight: 700;
  line-height: 28px;
  display: block;
}
.service-content-wrap .service-content > p {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  margin: 22px 0 26px 0;
}
.service-content-wrap .service-content .service-content-mdl-img img {
  width: 100%;
  border-radius: 4px;
}
.service-content-wrap .service-content .service-content-mdl-grid,
.service-content-wrap .service-content .service-content-planning-grid,
.service-content-wrap .service-content .service-content-project-grid,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-cnt-mdl-grid {
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-column-gap: 30px;
  align-items: center;
  margin-top: 28px;
}
.service-content-wrap .service-content .service-content-mdl-grid li {
  color: var(--paragraph_color);
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.service-content-wrap .service-content .service-content-mdl-grid li .icon {
  margin-right: 10px;
  color: var(--primary_color);
}
.service-content-wrap .service-content .service-details-tab .tabs-nav {
  align-items: center;
  margin: 42px 0 40px 0;
  flex-wrap: wrap;
}
.service-content-wrap .service-content .service-details-tab .tabs-nav li {
  background-color: var(--secondary_color);
  color: var(--white_color);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  padding: 12px 30px;
  margin-right: 5px;
  cursor: pointer;
  border: 0;
}
.service-content-wrap .service-content .service-details-tab .tabs-nav li:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.service-content-wrap
  .service-content
  .service-details-tab
  .tabs-nav
  li.react-tabs__tab--selected {
  background: var(--primary_color);
}
.service-content-wrap
  .service-content
  .service-details-tab
  .tabs-nav
  li.react-tabs__tab--selected:after {
  border-top: 10px solid var(--primary_color);
}
.service-content-wrap
  .service-content
  .service-details-tab
  .tabs-nav
  li:last-child {
  margin-right: 0;
}
.service-content-wrap .service-content .service-details-tab .tabs-content {
  background-color: var(--white_color);
  padding: 22px 25px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  border-radius: 4px;
  visibility: hidden;
  display: none;
}
.service-content-wrap
  .service-content
  .service-details-tab
  .tabs-content.react-tabs__tab-panel--selected {
  visibility: visible;
  display: block;
}
.service-content-wrap .service-content .service-details-tab .tabs-content p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
}
.service-content-wrap .service-content .service-content-planning-grid {
  align-items: flex-start;
  margin: 50px 0 0 0;
}
.service-content-wrap .service-content .service-content-planning-grid img {
  width: 100%;
  border-radius: 4px;
}
.service-content-wrap
  .service-content
  .service-content-planning-grid
  .planning-lists
  h3 {
  font-size: 25px;
  margin: 0 0 18px 0;
  line-height: 30px;
  color: var(--secondary_color);
  font-weight: 700;
}
.service-content-wrap
  .service-content
  .service-content-planning-grid
  .planning-lists
  p {
  color: var(--paragraph_color);
  margin: 0 0 25px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.service-content-wrap
  .service-content
  .service-content-planning-grid
  .planning-lists
  li {
  color: var(--paragraph_color);
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.service-content-wrap
  .service-content
  .service-content-planning-grid
  .planning-lists
  li
  .icon {
  color: var(--primary_color);
  margin-right: 10px;
}
.service-content-wrap
  .service-content
  .service-content-planning-grid
  .planning-lists
  li:last-child {
  margin-bottom: 0;
}
.service-content-wrap .service-content .service-content-project-grid {
  align-items: flex-start;
  margin-top: 33px;
}
.service-content-wrap .service-content .service-content-project-grid h3 {
  color: var(--secondary_color);
  font-size: 25px;
  line-height: 30px;
  display: block;
}
.service-content-wrap .service-content .service-content-project-grid p {
  margin: 18px 0 15px 0;
  font-weight: 500;
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
}
.service-content-wrap
  .service-content
  .service-content-project-grid
  p:last-child {
  margin-bottom: 0;
}

.service-content-wrap .service-details-grid {
  position: relative;
}

/* ----------------------------------------------
          About
---------------------------------------------- */
.about-st1-wrapper {
  padding: 120px 0 113px 0;
}
.about-st1-wrapper .about-st1-grid,
.about-wrapper .about-grid-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}
.about-st1-wrapper .about-left {
  padding-right: 65px;
}
.about-st1-wrapper .about-left .about-left-title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 23px;
  color: var(--secondary_color);
  line-height: 1.2;
}
.about-st1-wrapper .about-left .about-left-title > *,
.service-details-wrapper .service-sidebar .widget_contact ul li a:hover {
  color: var(--primary_color);
}
.about-st1-wrapper .about-left .about-left-desc {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
.about-st1-wrapper .about-right {
  position: relative;
}
.about-st1-wrapper .about-right:after {
  position: absolute;
  content: "";
  background-image: url(../images/dots.png);
  background-size: cover;
  background-position: center;
  left: -55px;
  bottom: -150px;
  z-index: -1;
  height: 90%;
  width: 90%;
  opacity: 0.2;
}
.about-st1-wrapper .about-right .about-right-img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}
.about-st1-wrapper .about-right .video-button-box {
  position: absolute;
  right: -15px;
  bottom: -25px;
  text-align: center;
  background-color: var(--secondary_color);
  color: var(--white_color);
  border-radius: 10px;
}
.about-st1-wrapper .about-right .video-button-box a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 30px;
  color: var(--white_color);
}
.about-st1-wrapper .about-right .video-button-box a:hover .icon {
  background: var(--primary_color);
  color: var(--white_color);
}
.about-st1-wrapper .about-right .video-button-box a .icon {
  width: 53px;
  height: 53px;
  line-height: 53px;
  color: var(--secondary_color);
  background-color: var(--white_color);
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 20px;
  transition: all 0.3s;
  position: relative;
  padding-top: 6px;
  margin-bottom: 5px;
}
.about-st1-wrapper .about-right .video-button-box a .icon:before {
  position: absolute;
  content: "";
  left: 50%;
  top: -4px;
  width: 30px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--secondary_color);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* History Details */
.history-area {
  position: relative;
  margin-top: 73px;
}
.history-area .history_img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}
.history-area .history-details-box {
  position: absolute;
  right: 0;
  bottom: 7px;
  z-index: 1;
  background-color: var(--white_color);
  width: 550px;
  padding: 33px 25px 45px 44px;
}
.history-area .history-details-box .history-details-title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 23px;
  color: var(--secondary_color);
  line-height: 1.2;
}
.history-area .history-details-box .history-details-title > * {
  color: var(--primary_color);
}
.history-area .history-details-box .history-details-desc {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
/* About */
.about-wrapper {
  position: relative;
  overflow: hidden;
  padding: 20px 0 100px 0;
}
.about-wrapper:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  content: "";
  /* background-image: url(../images/dots3.png); */
  background-position: center;
  transform: rotate(-10deg);
  z-index: -1;
  opacity: 0.1;
}
.about-wrapper .about-grid-wrap .about-right {
  text-align: right;
}
.about-wrapper .about-grid-wrap .about-left .about-left-desc {
  width: 100%;
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  /* margin: 4px 0 16px 0; */
  text-indent: 70px;
  /* padding:20px 20px; */
}
.about-wrapper .about-grid-wrap .about-left a {
  color: var(--secondary_color);
  text-transform: capitalize;
  transition: all 0.3s;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  padding: 0 14px 0 0;
}
.about-wrapper .about-grid-wrap .about-left a:hover {
  padding-right: 16px;
  color: var(--primary_color);
}
.about-wrapper .about-grid-wrap .about-left a > * {
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 4px;
}
.about-wrapper .about-iconbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-column-gap: 30px;
  margin: 32px 0 0 0;
}
.about-wrapper .about-iconbox-grid .about-iconbox {
  background: linear-gradient(
    45deg,
    var(--white_color) 88%,
    var(--light_white) 30%
  );
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  position: relative;
  padding: 50px 20px 33px 20px;
  text-align: center;
  overflow: hidden;
}
.about-wrapper .about-iconbox-grid .about-iconbox:before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary_color);
  left: 0;
  border-radius: 4px;
  transform: scale(0);
  transition: all 0.3s;
}
.about-wrapper .about-iconbox-grid .about-iconbox:hover:before {
  transform: scale(1);
}
.about-wrapper .about-iconbox-grid .about-iconbox .about-iconbox-icon {
  font-size: 55px;
  color: var(--primary_color);
}
.about-wrapper .about-iconbox-grid .about-iconbox .about-iconbox-title {
  font-size: 18px;
  color: var(--secondary_color);
  font-weight: 700;
  line-height: 22px;
  margin-top: 15px;
  transition: all 0.3s;
}
.about-wrapper .about-iconbox-grid .about-iconbox:hover .about-iconbox-title {
  color: var(--primary_color);
}
.about-wrapper .about-right {
  position: relative;
}
.about-wrapper .about-right img {
  border-radius: 4px;
  position: relative;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.about-wrapper .about-right .about_img_0 {
  z-index: 1;
  width: 470px;
}
.about-wrapper .about-right .about_img_1 {
  z-index: 2;
  width: 353px;
  position: absolute;
  left: 66px;
  top: 212px;
}
.about-wrapper .about-right .about_img_2 {
  z-index: 3;
  width: 306px;
  margin-top: 59px;
}
.about-wrapper .about-right .video-btn {
  position: absolute;
  top: 40%;
  left: 48%;
  transform: translate(-50%, 50%);
  color: var(--secondary_color);
  font-size: 18px;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background-color: var(--white_color);
  text-transform: capitalize;
  font-weight: 700;
  transition: all 0.3s;
  z-index: 4;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}
.about-wrapper .about-right .video-btn:hover {
  background: var(--primary_color);
  color: var(--white_color);
}
/* About History area */
.about-history-wrapper {
  position: relative;
  background-color: #f6f8f9;
  padding: 120px 0 120px 0;
}
.about-history-wrapper .sec-heading .sec__meta {
  margin: 0;
}
.about-history-wrapper .about-history-grid {
  grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  grid-column-gap: 30px;
}
.about-history-wrapper .about-history-grid .about-history-left p {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  padding-right: 50px;
}
.about-history-wrapper .about-history-grid .about-history-left ul {
  margin: 30px 0 40px 0;
}
.about-history-wrapper .about-history-grid .about-history-left ul li {
  font-size: 16px;
  color: var(--secondary_color);
  position: relative;
  margin-bottom: 10px;
  font-weight: 600;
  padding-left: 20px;
}
.about-history-wrapper .about-history-grid .about-history-left ul li:before {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--primary_color);
}
.about-history-wrapper
  .about-history-grid
  .about-history-right
  .about-history-right-img-grid {
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-column-gap: 22px;
}
.about-history-wrapper
  .about-history-grid
  .about-history-right
  .about-history-right-img-grid
  img {
  width: 100%;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  z-index: 3;
  position: relative;
  border-radius: 30px;
}
.about-history-wrapper
  .about-history-grid
  .about-history-right
  .about-history-trusted-box {
  margin-top: 25px;
  padding: 24px;
  color: var(--secondary_color);
  background-color: var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.02);
  border-radius: 30px;
}
.about-history-wrapper
  .about-history-grid
  .about-history-right
  .about-history-trusted-box
  h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 23px;
  color: var(--secondary_color);
  line-height: 1.2;
}
.about-history-wrapper
  .about-history-grid
  .about-history-right
  .about-history-trusted-box
  p {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

/* ----------------------------------------------
          CounterUp
---------------------------------------------- */
.counterup-wrapper {
  background: var(--secondary_color) url(../images/dots2.png) center;
  background-size: cover;
  padding: 118px 0 120px 0;
  overflow: hidden;
}
.counterup-wrapper .sec-heading {
  margin-bottom: 50px;
}
.counterup-wrapper .sec-heading .sec__meta {
  color: #b9c2ce;
}
.counterup-wrapper .sec-heading .sec__title {
  color: var(--light_white);
}
.counterup-wrapper .counterup-grid-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-column-gap: 30px;
  color: var(--white_color);
}
.counterup-wrapper .counterup-grid-wrap .counterup-items,
.counterup-wrapper .counterup-grid-wrap .counterup-items .counterup-iconbox {
  position: relative;
}
.counterup-wrapper .counterup-grid-wrap .counterup-items:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  width: 1px;
  height: 100%;
  position: absolute;
  right: -20px;
}
.counterup-wrapper .counterup-grid-wrap .counterup-items:last-child:before {
  display: none;
}
.counterup-wrapper .counterup-grid-wrap .counterup-items .counterup-iconbox {
  position: relative;
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  line-height: 75px;
}
.counterup-wrapper
  .counterup-grid-wrap
  .counterup-items
  .counterup-iconbox:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  transform: translateY(-50%) rotate(-33deg);
  transition: all 0.3s;
}
.counterup-wrapper .counterup-grid-wrap .counterup-items .counterup-iconbox * {
  font-size: 60px;
}
.counterup-wrapper
  .counterup-grid-wrap
  .counterup-items:nth-child(2)
  .counterup-iconbox
  *,
.counterup-wrapper
  .counterup-grid-wrap
  .counterup-items:nth-child(3)
  .counterup-iconbox
  * {
  font-size: 65px;
}
.counterup-wrapper .counterup-grid-wrap .counterup-items .counterup-number {
  font-size: 65px;
  font-weight: 600;
  display: inline-block;
  margin-top: 45px;
  margin-bottom: 32px;
}
.counterup-wrapper .counterup-bottom-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 60px;
  padding-top: 60px;
  position: relative;
  flex-wrap: wrap;
}
.counterup-wrapper .counterup-bottom-wrap:before,
.counterup-wrapper .counterup-bottom-wrap:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.3);
  width: 60px;
  height: 1px;
  position: absolute;
  left: 0;
  top: -1px;
}
.counterup-wrapper .counterup-bottom-wrap:after {
  left: auto;
  right: 0;
}
.counterup-wrapper .counterup-bottom-wrap p {
  color: var(--white_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}
.counterup-wrapper .counterup-bottom-wrap a {
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 10px 40px 10px 25px;
  line-height: 1.5;
  margin-left: 10px;
}
.counterup-wrapper .counterup-bottom-wrap a:hover {
  background: var(--white_color);
  color: var(--primary_color);
}

/* ----------------------------------------------
          Tabs
---------------------------------------------- */
.tabs-wrapper {
  background-color: #f6f8f9;
  padding: 1% 0 120px 0;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex {
  display: flex;
  align-items: center;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex.img_right .tabs-left {
  order: 2;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex.img_right .tabs-right {
  order: 1;
}

.tabs-wrapper .tabs-content-wrap .tabs-content {
  text-align: left;
  background-color: var(--white_color);
  padding-left: 0;
  border-radius: 4px;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex img {
  border-radius: 4px 0 0 4px;
  margin-bottom: -7px;
}
.tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-right {
  padding: 0 40px;
}
.tabs-wrapper .tabs-content-wrap .tabs-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 40px 0;
  flex-wrap: wrap;
}
.tabs-wrapper .tabs-content-wrap .tabs-nav li {
  color: var(--secondary_color);
  background-color: var(--white_color);
  display: block;
  border: 1px solid var(--secondary_color);
  padding: 15px 30px;
  margin-top: 10px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.tabs-wrapper .tabs-content-wrap .tabs-nav li:focus {
  outline: none;
  box-shadow: none;
}
.tabs-wrapper .tabs-content-wrap .tabs-nav li:hover,
.tabs-wrapper .tabs-content-wrap .tabs-nav li.react-tabs__tab--selected {
  background-color: var(--primary_color);
  color: var(--white_color);
  border-color: var(--primary_color);
}
.tabs-wrapper .tabs-content-wrap .tabs-nav li:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.tabs-wrapper .tabs-content-wrap .tabs-nav li.react-tabs__tab--selected:before {
  border-top: 10px solid var(--primary_color);
}
/* Advance Tab */
.advancetab-wrapper {
  position: relative;
  padding: 112px 0 115px 0;
}
.advancetab-wrapper .sec-heading .sec__title {
  margin-bottom: 49px;
}
.advancetab-wrapper .advancetab-grid {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 30px;
}
.advancetab-wrapper .advancetab-grid .advancetab-nav {
  grid-column: span 2;
}
.advancetab-wrapper .advancetab-grid .advancetab-nav li {
  cursor: pointer;
  color: var(--secondary_color);
  background-color: var(--white_color);
  display: block;
  border: 1px solid var(--secondary_color);
  padding: 25px 30px;
  margin-top: 10px;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 4px;
  transition: 0.3s;
  position: relative;
}
.advancetab-wrapper .advancetab-grid .advancetab-nav li:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  right: -16px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-nav
  li.react-tabs__tab--selected:after {
  border-top-color: var(--primary_color);
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-nav
  li.react-tabs__tab--selected,
.advancetab-wrapper .advancetab-grid .advancetab-nav li:hover {
  background: var(--primary_color);
  color: var(--white_color);
  border-color: var(--primary_color);
}
.advancetab-wrapper .advancetab-grid .advancetab-nav li h3 {
  font-weight: 600;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-nav
  li.react-tabs__tab--selected
  p,
.advancetab-wrapper .advancetab-grid .advancetab-nav li:hover p {
  color: var(--white_color);
}
.advancetab-wrapper .advancetab-grid .advancetab-nav li p {
  font-size: 15px;
  font-weight: 500;
  display: block;
  margin-top: 6px;
  line-height: 25px;
  color: var(--paragraph_color);
  transition: 0.3s;
}
.advancetab-wrapper .advancetab-grid .advancetab-content {
  grid-column: span 4;
  padding-left: 20px;
}
.advancetab-wrapper .advancetab-grid .advancetab-content .content-top-box {
  max-width: 545px;
  margin-bottom: 40px;
}
.advancetab-wrapper .advancetab-grid .advancetab-content .content-top-box h2 {
  font-size: 25px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 19px;
  line-height: 34px;
  max-width: 400px;
}
.advancetab-wrapper .advancetab-grid .advancetab-content .content-top-box p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
}
.advancetab-wrapper .advancetab-grid .advancetab-content .content-box-grid {
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 30px;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item {
  background-color: var(--white_color);
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  color: var(--secondary_color);
  padding: 40px 30px 37px 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:before {
  position: absolute;
  content: "";
  background: url("../images/img1.jpg") center/cover no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  transform: scale(1.1);
  border-radius: 4px;
  z-index: -1;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:hover:before,
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:hover:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 0;
  border-radius: 4px;
  background: rgba(59, 62, 121, 0.9);
  z-index: -1;
  transform: scale(1.1);
  transition: 0.3s;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item
  .icon {
  font-size: 55px;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item
  h2 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 23px;
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item
  p {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph_color);
}
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:hover
  .icon,
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:hover
  p,
.advancetab-wrapper
  .advancetab-grid
  .advancetab-content
  .content-box-grid
  .item:hover
  h2 {
  color: var(--white_color);
}

/* ----------------------------------------------
          CTA
---------------------------------------------- */
.cta-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--paragraph_color) url(../images/img6.jpg) center/cover
    no-repeat fixed;
  position: relative;
  z-index: 1;
}
.cta-wrapper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary_color);
  opacity: 0.9;
  z-index: -1;
}
.cta-wrapper .row {
  align-items: center;
}
.cta-wrapper h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  color: var(--white_color);
}
.cta-wrapper a {
  background-color: var(--white_color);
  color: var(--secondary_color);
}
.cta-wrapper a:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
}
/* cta 2 */
.ctaform-wrapper {
  position: relative;
  padding: 190px 0 120px 0;
  background: linear-gradient(-3deg, var(--white_color) 86%, #f6f8f9 50%);
}
.ctaform-wrapper .sec-heading .sec__title {
  margin-bottom: 30px;
}
.ctaform-wrapper .sec-heading .sec__meta {
  margin-bottom: 2px;
}
.ctaform-wrapper .ctaform-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  grid-column-gap: 40px;
  align-items: flex-start;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-column-gap: 30px;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item {
  border-radius: 4px;
  margin: 0px;
  padding: 32px 20px 26px 20px;
  position: relative;
  transition: all 0.3s;
  z-index: 1;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  overflow: hidden;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item:before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary_color);
  left: 0;
  border-radius: 4px;
  transform: scale(0);
  transition: all 0.3s;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item:hover:before {
  transform: scale(1);
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item .icon {
  text-align: center;
  color: var(--primary_color);
  display: inline-block;
  position: relative;
  font-size: 45px;
  margin-bottom: 20px;
  z-index: 1;
  transition: all 0.3s;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item:last-child {
  padding-top: 22px;
}
.ctaform-wrapper
  .ctaform-grid
  .ctaform-contact-info-grid
  .item:last-child
  .icon {
  font-size: 63px;
  margin-bottom: 12px;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item p {
  color: var(--paragraph_color);
  font-weight: 500;
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: var(--secondary_color);
}
.ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid .item:hover h2 {
  color: var(--primary_color);
}
.ctaform-wrapper .ctaform-grid .ctaform-right {
  margin-top: 8px;
  box-shadow: 0px 0px 40px 0px rgba(82, 85, 90, 0.1);
  padding: 35px 35px;
  border-radius: 4px;
}
.ctaform-wrapper .ctaform-grid .ctaform-right .form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(227px, 1fr));
  grid-column-gap: 15px;
}
.ctaform-wrapper .ctaform-grid .ctaform-right button {
  font-weight: 600;
}
.form-white-bg .ctaform-wrapper {
  background: var(--white_color);
  padding-top: 112px;
  padding-bottom: 50px;
}
.service-main .ctaform-wrapper {
  padding-top: 112px;
}

/* ----------------------------------------------
          Testimonial
---------------------------------------------- */
.testimonial-wrapper {
  position: relative;
  padding: 112px 0 114px 0;
  overflow: hidden;
}
.testimonial-wrap .sec-heading .sec__title {
  margin-bottom: 44px;
}
.testimonial-wrapper .shape_img {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 10px 30px rgba(246, 107, 193, 0.2);
}
.testimonial-wrapper .shape_img:nth-child(1) {
  top: -5px;
  left: -10px;
}
.testimonial-wrapper .shape_img:nth-child(2) {
  top: 26%;
  left: 11%;
  width: 70px;
  height: 70px;
}
.testimonial-wrapper .shape_img:nth-child(3) {
  top: 55%;
  left: 6%;
  width: 60px;
  height: 60px;
}
.testimonial-wrapper .shape_img:nth-child(4) {
  top: auto;
  bottom: 8%;
  left: 15%;
  width: 50px;
  height: 50px;
}
.testimonial-wrapper .shape_img:nth-child(5) {
  top: 10px;
  left: auto;
  right: 5px;
}
.testimonial-wrapper .shape_img:nth-child(6) {
  top: 30%;
  left: auto;
  right: 10%;
  width: 70px;
  height: 70px;
}
.testimonial-wrapper .shape_img:nth-child(7) {
  top: auto;
  left: auto;
  bottom: 30%;
  right: 6%;
  width: 60px;
  height: 60px;
}
.testimonial-wrapper .shape_img:nth-child(8) {
  top: auto;
  left: auto;
  bottom: 3%;
  right: 14%;
  width: 45px;
  height: 45px;
}
.testimonial-wrapper .sec-heading {
  margin-bottom: 35px;
}
.testimonial-wrapper .testimonial-items {
  position: relative;
}
.testimonial-wrapper .owl-item {
  padding: 15px;
}
.testimonial-wrapper .testimonial-items p.desc {
  background-color: var(--white_color);
  border-radius: 4px;
  transition: all 0.3s;
  border: 2px solid transparent;
  padding: 22px 20px 22px 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  box-shadow: 0 0 18px rgba(82, 85, 90, 0.1);
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
}
.testimonial-wrapper .testimonial-items:hover p.desc {
  border-color: var(--primary_color);
}
.testimonial-wrapper .testimonial-items p.desc .quote_icon {
  font-size: 85px;
  color: var(--secondary_color);
  position: absolute;
  right: 18px;
  bottom: -34px;
  opacity: 0.1;
}
.testimonial-wrapper .testimonial-items p.desc:before {
  position: absolute;
  content: "";
  bottom: -14px;
  left: 36px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
}
.testimonial-wrapper .testimonial-items:hover p.desc:before {
  border-top-color: var(--primary_color);
}
.testimonial-wrapper .testimonial-items img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  margin-right: 20px;
}
.testimonial-wrapper .testimonial-items .testimonial-bottom-flex {
  display: flex;
  align-items: center;
}
.testimonial-wrapper .testimonial-items .testimonial-bottom-flex .name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--secondary_color);
}
.testimonial-wrapper .testimonial-items .testimonial-bottom-flex .city {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--secondary_color);
  opacity: 0.5;
  margin-bottom: 5px;
}
.testimonial-wrapper .testimonial-items .testimonial-bottom-flex .stars,
.testimonial-wrapper .testimonial-items .testimonial-bottom-flex .stars > * {
  font-size: 16px;
  color: var(--primary_color);
}
.testimonial-wrapper .owl-dots {
  margin-top: 60px !important;
}
.testimonial-wrapper .owl-dots > * {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid var(--secondary_color);
  background: none;
  margin: 0 3px;
  transition: 0.3s;
}
.testimonial-wrapper .owl-dots .active,
.testimonial-wrapper .owl-dots > *:hover {
  background: var(--primary_color);
  border-color: var(--primary_color);
}
.testimonial-wrapper .owl-dots span {
  display: none !important;
}

/* ----------------------------------------------
          Portfolio
---------------------------------------------- */
.portfolio-details-wrapper {
  padding: 123px 0 120px 0;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-image {
  position: relative;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-image:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 18px solid var(--white_color);
  transform: translateX(-50%);
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-image:after {
  position: absolute;
  content: "";
  border: 12px solid rgba(35, 61, 99, 0.1);
  height: 100%;
  width: 100%;
  left: -50px;
  bottom: -49px;
  z-index: -1;
  border-radius: 4px;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-image
  img {
  width: 100%;
  border-radius: 10px 0 10px 0;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-column-gap: 30px;
  align-items: center;
  margin: 30px 0 29px 0;
  border-bottom: 1px solid var(--gray_color);
  padding: 0 0 28px;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  h4 {
  font-size: 16px;
  color: var(--secondary_color);
  font-weight: 600;
  text-transform: capitalize;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  > span {
  align-items: center;
  color: var(--paragraph_color);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  .icon {
  font-size: 15px;
  color: var(--primary_color);
  margin-right: 5px;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  .weblink {
  align-items: center;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  a {
  color: var(--paragraph_color);
  font-weight: 500;
}
.portfolio-details-wrapper
  .portfolio-details-image-box
  .portfolio-details-info-grid
  .item
  a:hover {
  color: var(--primary_color);
}
.portfolio-details-wrapper .portfolio-details-content {
  margin: 0 0 55px 0;
}
.portfolio-details-wrapper .portfolio-details-content h1 {
  font-size: 28px;
  color: var(--secondary_color);
  font-weight: 700;
  margin: 0 0 28px 0;
}
.portfolio-details-wrapper .portfolio-details-content p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 20px 0;
}
.portfolio-details-wrapper .portfolio-details-project-lists,
.portfolio-details-wrapper .related-projects-wrap .related-projects-grid {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: 30px;
  align-items: flex-start;
  margin: 0 0 41px 0;
}
.portfolio-details-wrapper .portfolio-details-project-lists .item h3 {
  font-size: 25px;
  color: var(--secondary_color);
  font-weight: 700px;
  text-transform: capitalize;
  margin: 0 0 25px 0;
}
.portfolio-details-wrapper .portfolio-details-project-lists .item p {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  margin: 0 0 20px 0;
  line-height: 28px;
}
.portfolio-details-wrapper .portfolio-details-project-lists .item img {
  width: 100%;
}
.portfolio-details-wrapper .portfolio-details-project-lists .item ul li {
  color: var(--paragraph_color);
  font-weight: 500;
  margin: 0 0 10px 0;
  align-items: center;
}
.portfolio-details-wrapper .portfolio-details-project-lists .item ul li .icon {
  color: var(--primary_color);
  margin-right: 3px;
}
.portfolio-details-wrapper
  .portfolio-details-project-lists
  .solution
  ul
  li
  .icon {
  margin-right: 10px;
}
.portfolio-details-wrapper .related-projects-wrap > h2 {
  font-size: 28px;
  margin: 0 0 30px 0;
  font-weight: 700;
  color: var(--secondary_color);
}
.portfolio-details-wrapper .related-projects-wrap .related-projects-grid {
  margin: 0;
}
.portfolio-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.portfolio-item img {
  width: 100%;
  border-radius: 4px;
  transition: all 0.5s;
  margin-bottom: -7px;
}
.portfolio-item:hover img {
  transform: scale(1.2);
}
.portfolio-item .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 62, 121, 0.9);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  align-items: center;
  justify-content: center;
}
.portfolio-item:hover .overlay {
  opacity: 1;
  visibility: visible;
}
.portfolio-item .overlay a {
  position: absolute;
  width: 100%;
  color: var(--white_color);
}
.portfolio-item .overlay h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px 0;
  line-height: 1.2;
  transition: all 0.5s;
}
.portfolio-item .overlay a:hover h4 {
  color: var(--primary_color);
}
.portfolio-item .overlay span {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: #a0aec6;
}

/* Portfolio Grid */
.portfolio-grid-wrapper {
  padding: 115px 0 90px 0;
}
.portfolio-grid-wrapper .grid {
  display: block;
  width: 100%;
}
.portfolio-grid-wrapper .grid .grid-item:nth-child(3),
.portfolio-grid-wrapper .grid .grid-item:nth-child(6),
.portfolio-grid-wrapper .grid .grid-item:nth-child(9),
.portfolio-grid-wrapper .grid .grid-item:last-child {
  margin-right: 0;
}
.portfolio-grid-wrapper .grid .grid-item:first-child,
.portfolio-grid-wrapper .grid .grid-item:nth-child(4),
.portfolio-grid-wrapper .grid .grid-item:nth-child(7),
.portfolio-grid-wrapper .grid .grid-item:nth-child(7) {
  margin-left: 0;
}
.portfolio-grid-wrapper .grid .grid-item img {
  position: relative;
  margin-bottom: -7px;
}
.portfolio-grid-wrapper .grid .grid-item {
  width: 31.6%;
  height: 248px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.portfolio-grid-wrapper .portfolio-grid-top {
  max-width: 770px;
  position: relative;
  margin: auto;
}
.portfolio-grid-wrapper .portfolio-grid-top .filters {
  border: 2px solid rgba(103, 114, 134, 0.1);
  border-radius: 50px;
  padding: 15px 0;
  margin: 29px 0 60px 0;
}
.portfolio-grid-wrapper .portfolio-grid-top .filters button {
  display: inline-block;
  padding: 6px 20px;
  cursor: pointer;
  color: var(--paragraph_color);
  border-radius: 30px;
  position: relative;
  transition: all 0.3s;
  font-size: 16px;
  background: none;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 25px;
}
.portfolio-grid-wrapper .portfolio-grid-top .filters button.active {
  background-color: var(--primary_color);
  color: var(--white_color);
  box-shadow: 0 6px 30px 0 rgba(246, 107, 93, 0.2);
}

/* ----------------------------------------------
          Team
---------------------------------------------- */
.team-wrapper {
  /* position: relative;
  padding: 30px 0 0px 0; */
  position: relative;
  background-color: #F4F4F4;
  padding: 40px 20px;
  margin: 15px 0px;
   /*background: #1C1D21;*/

   bottom: 0;
   left: 0;
   right: 0;
   top: 0;
   /*opacity: 0.8;*/
   /*-webkit-transition: 0.2s opacity;*/
   /*transition: 0.2s opacity;*/
}

.team-wrapper .team-grid .team img {
  max-width: 50%; /* Set a maximum width for the image */
  max-height: 50%; /* Set a maximum height for the image */
  object-fit: cover; /* Maintain aspect ratio while covering the container */
}
/* .team-wrapper .team-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-column-gap: 15px;
  align-items: flex-start;
  align-content: space-evenly;
} */
/* Add these styles to your existing stylesheet */

.team-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.team-container1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.team-wrapper .sec-heading {
  margin-bottom: 59px;
}
.team-wrapper .team-grid .team {
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  margin-bottom: 30px;
}
.team-wrapper .team-grid .team .team-img {
  position: relative;
}
.team-wrapper .team-grid .team .team-img:before {
  position: absolute;
  content: "";
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
}
.team-wrapper .team-grid .team img {
  width: 100%;
}
.team-wrapper .team-grid .team .team-img .socials {
  padding: 10px 25px 10px 20px;
  background: var(--primary_color);
  border-radius: 30px;
  position: absolute;
  bottom: 7px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 9;
  transition: 0.3s;
}
.team-wrapper .team-grid .team:hover .team-img .socials {
  bottom: -15px;
  opacity: 1;
}
.team-wrapper .team-grid .team .team-img .socials > * {
  color: var(--white_color);
  font-size: 20px;
  margin-right: 15px;
  display: inline-block;
  margin-top: 5px;
  transition: 0.3s;
}
.team-wrapper .team-grid .team .team-img .socials > *:last-child {
  margin-right: 0;
}
.team-wrapper .team-grid .team .team-img .socials > *:hover {
  color: var(--secondary_color);
}
.team-wrapper .team-grid .team .name {
  font-size: 15px;
  font-weight: 700;
  color: var(--secondary_color);
  text-transform: capitalize;
  margin-bottom: 3px;
}
.team-wrapper .team-grid .team .designation {
  font-size: 15px;
  color: var(--paragraph_color);
  text-transform: capitalize;
  font-weight: 500;
}
.team-wrapper .team-grid .team .team-content {
  padding: 30px 20px 35px 20px;
  border: 1px solid var(--gray_color);
  border-radius: 0 0 4px 4px;
  background-color: var(--white_color);
  position: relative;
  margin-top: -7px;
}
.team-wrapper .team-grid .team .team-content .theme-button {
  border-radius: 30px;
  margin-top: 10px;
}
.teamgrid-page .form-white-bg {
  padding-top: 5px;
}

/* Team Details */
.team-details-wrapper {
  position: relative;
  padding: 143px 0 50px 0;
}
.team-details-wrapper .team-details-grid-top {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 30px;
  align-items: flex-start;
}
.team-details-wrapper .team-details-grid-top .team-details-content {
  grid-column: span 4;
  padding-left: 30px;
}
.team-details-wrapper .team-details-grid-top .team-details-content .name {
  font-size: 35px;
  color: var(--secondary_color);
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.2;
}
.team-details-wrapper .team-details-grid-top .team-details-content .skills {
  font-size: 16px;
  color: var(--paragraph_color);
  display: block;
  font-weight: 500;
}
.team-details-wrapper .team-details-grid-top .team-details-content .desc {
  font-size: 16px;
  color: var(--paragraph_color);
  display: block;
  font-weight: 500;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact {
  margin-top: 19px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li {
  display: block;
  position: relative;
  margin-bottom: 12px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li
  a {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;
  line-height: 20px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li
  a:hover {
  color: var(--primary_color);
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li
  a:hover
  .icon {
  background: var(--primary_color);
  color: var(--white_color);
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li
  a
  .icon {
  margin-right: 10px;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 40px;
  text-align: center;
  color: var(--secondary_color);
  border-radius: 50%;
  transition: all 0.3s;
  position: relative;
  background-color: rgba(35, 61, 99, 0.1);
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-details-contact
  li
  a
  .icon:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -3px;
  transform: translateX(-50%);
  width: 22px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-expertise-education-grid {
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  grid-column-gap: 30px;
  margin-top: 27px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-expertise-education-grid
  h2 {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 25px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-expertise-education-grid
  ul
  li {
  position: relative;
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 20px;
}
.team-details-wrapper
  .team-details-grid-top
  .team-details-content
  .team-expertise-education-grid
  ul
  li:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 7px;
  height: 7px;
  background-color: var(--primary_color);
  border-radius: 50%;
}
.team-details-wrapper .team-details-grid-top .team-details-img {
  grid-column: span 2;
  position: relative;
  z-index: 1;
}
.team-details-wrapper .team-details-grid-top .team-details-img:before {
  position: absolute;
  content: "";
  border: 10px solid rgba(35, 61, 99, 0.1);
  height: 100%;
  width: 100%;
  left: -45px;
  bottom: -40px;
  z-index: -1;
  border-radius: 4px;
}
.team-details-wrapper .team-details-grid-top .team-details-img:after {
  position: absolute;
  content: "";
  height: 50%;
  width: 50%;
  left: -68px;
  top: 140px;
  background: url(../images/dots.png) center/cover;
  z-index: -1;
  opacity: 0.2;
}
.team-details-wrapper .team-details-grid-top .team-details-img img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.team-details-wrapper .team-details-grid-bottom {
  margin: 50px 0 0 0;
}
.team-details-wrapper .team-details-grid-bottom,
.faq-grid,
.service-details-wrapper .service-details-grid,
.blog-details-wrapper .blog-details-grid,
.contact-form-wrapper .contact-form-map-grid,
.shop-sidebar-wrapper .shop-sidebar-grid {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 30px;
}
.team-details-wrapper .team-details-grid-bottom .left,
.faq-grid .left,
.service-details-wrapper .service-details-grid .service-content-wrap,
.blog-details-wrapper .blog-details-grid .blog-details-left,
.contact-form-wrapper .contact-form-map-grid .left,
.shop-sidebar-wrapper .shop-sidebar-grid .right {
  grid-column: span 4;
}
.shop-sidebar-wrapper .shop-sidebar-grid .right {
  grid-column: span 5;
}
.team-details-wrapper .team-details-grid-bottom .left > h3 {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  margin: 0 0 25px;
}
.team-details-wrapper .team-details-grid-bottom .left > p {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
}
.team-details-wrapper .team-details-grid-bottom .right,
.faq-grid .right,
.service-details-wrapper .service-details-grid .service-sidebar,
.blog-details-wrapper .blog-details-grid .blog-details-right,
.contact-form-wrapper .contact-form-map-grid .right,
.shop-sidebar-wrapper .shop-sidebar-grid .left {
  grid-column: span 2;
}
/* Sidebar */
.sidebar-widget {
  margin-bottom: 40px;
}
.sidebar-widget:last-child {
  margin-bottom: 0;
}
.sidebar-widget.sidebar-contact-form {
  background-color: var(--secondary_color);
  padding: 32px 33px 39px 33px;
  border-radius: 20px 0 20px 0;
  color: var(--white_color);
}
.sidebar-widget.sidebar-contact-form h4 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 26px;
}
.sidebar-widget.sidebar-contact-form p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}
.sidebar-widget.sidebar-contact-form .theme-button {
  background: var(--white_color);
  color: var(--secondary_color);
  padding: 0 45px 0 30px;
}
.sidebar-widget.sidebar-contact-form .theme-button:hover {
  background: var(--primary_color);
  color: var(--white_color);
}
.sidebar-widget.button .theme-button {
  padding-right: 45px;
}
.sidebar-widget h2 {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--secondary_color);
  margin-bottom: 30px;
}
.recent-posts ul li {
  border-bottom: 1px solid var(--gray_color);
  padding-bottom: 25px;
  margin-bottom: 25px;
  align-items: center;
}
.recent-posts li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.recent-posts img {
  border-radius: 8px;
  margin-right: 15px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  width: 90px;
}
.recent-posts li .body > a {
  color: var(--secondary_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: block;
  transition: all 0.3s;
}
.recent-posts li .body a:hover {
  color: var(--primary_color);
}
.recent-posts li .body span {
  font-size: 14px;
  font-weight: 500;
  color: var(--paragraph_color);
}
.recent-posts li .body span a {
  color: var(--secondary_color);
}
.sidebar-social-profile ul {
  align-items: center;
}
.sidebar-social-profile ul li {
  margin-right: 3px;
}
.sidebar-social-profile ul li:last-child {
  margin-right: 0;
}
.sidebar-social-profile ul li a:before {
  position: absolute;
  content: "";
  left: 50%;
  top: -3px;
  transform: translateX(-50%);
  width: 22px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.sidebar-social-profile ul li a {
  color: var(--paragraph_color);
  font-size: 16px;
  width: 38px;
  height: 38px;
  line-height: 45px;
  text-align: center;
  display: block;
  border-radius: 50%;
  transition: all 0.3s;
  position: relative;
  background-color: rgba(35, 61, 99, 0.1);
}
.sidebar-social-profile ul li a:hover {
  background: var(--primary_color);
  color: var(--white_color);
}

/* ----------------------------------------------
          FAQ
---------------------------------------------- */
.faq-grid {
  padding: 138px 0 120px 0;
}
.accordion-wrapper .accordion__item {
  margin-bottom: 20px;
}
.accordion-wrapper .accordion__item:last-child {
  margin-bottom: 0;
}
.accordion-wrapper .accordion__item .accordion__button {
  cursor: pointer;
  color: var(--secondary_color);
  width: 100%;
  padding: 20px 30px 18px 30px;
  display: block;
  border: 1px solid #cccdce;
  font-weight: 600;
  font-size: 20px;
  position: relative;
  transition: all 0.3s;
  border-radius: 4px;
  position: relative;
}
.accordion-wrapper .accordion__item .accordion__button:hover {
  color: var(--primary_color);
}
.accordion-wrapper .accordion__item .accordion__button:before,
.accordion-wrapper .accordion__item .accordion__button:after {
  content: "";
  position: absolute;
  right: 30px;
  width: 10px;
  height: 2px;
  top: 32px;
  background: #cccdce;
  transition: all 0.3s;
}
.accordion-wrapper .accordion__item .accordion__button:hover:before,
.accordion-wrapper .accordion__item .accordion__button:hover:after {
  background: var(--primary_color);
}
.accordion-wrapper .accordion__item .accordion__button:after {
  transform: rotate(90deg);
}
.accordion-wrapper
  .accordion__item
  .accordion__button[aria-expanded="true"]:after {
  transform: rotate(0);
  background: var(--primary_color);
}
.accordion-wrapper .accordion__item .accordion__button[aria-expanded="true"] {
  color: var(--primary_color);
  border-radius: 4px 4px 0 0;
}
.accordion-wrapper .accordion__item .accordion__panel {
  padding: 20px 30px 25px 30px;
  border: 1px solid #cccdce;
  border-top: none;
  background-color: var(--white_color);
  box-shadow: 1px 7px 41px -8px rgba(82, 85, 90, 0.1);
  border-radius: 0 0 5px 5px;
}
.accordion-wrapper .accordion__panel p {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
}

/* ----------------------------------------------
          Charts
---------------------------------------------- */
.chart-wrapper {
  position: relative;
  padding: 115px 0 115px 0;
}
.chart-wrapper .sec-heading .sec__meta {
  margin-bottom: 2px;
}
.chart-wrapper .chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  grid-column-gap: 30px;
}
.chart-wrapper .chart-grid .chart-left .content-title {
  font-size: 20px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 15px;
}
.chart-wrapper .chart-grid .chart-left .content-desc {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 35px;
}

/* ----------------------------------------------
          Clients
---------------------------------------------- */
.clients-wrapper {
  position: relative;
  padding: 5px 0 60px 0;
}
.clients-wrapper .items {
  vertical-align: center;
}
.clients-wrapper .items img {
  width: auto;
  margin: auto;
}
.about-page .services-area {
  padding: 113px 0 120px 0;
}
.about-page .clients-wrapper {
  padding-bottom: 0;
}
.home-v2 .clients-wrapper {
  padding: 120px 0;
}
.clients-wrapper-bg .clients-wrapper {
  background-color: #f6f8f9;
  padding: 80px 0 80px 0;
}
.invest-link {
  text-align: center;

}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-invest {
  color: #fff;
  font-family: 'Trebuchet MS', sans-serif;
  background-color: #162d2f;
  border-color: #31adb7;
  font-size: 20px;
  margin-right: 10px;
}
.about {
  position: relative;
  text-align: justify;
  padding: 40px 60px;
  border: 1px solid #EEE;
  margin: 15px 0px;
}
.btn:hover {
  color: #333;
  text-decoration: none;
}
/* a:hover {
  text-decoration: none;
  outline: none;
  opacity: 0.8;
  color: #008080;
} */
a {

  transition: 0.2s opacity;
}

/* ----------------------------------------------
          Pricing Plan
---------------------------------------------- */
.pricing-wrapper {
  position: relative;
  padding: 185px 0 118px 0;
  background: linear-gradient(-3deg, #f6f8f9 91%, var(--white_color) 50%);
}
.pricing-wrapper .sec-heading .sec__title {
  margin-bottom: 50px;
}
.pricing-wrapper .tabs-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}
.pricing-wrapper .tabs-nav li {
  background-color: transparent;
  color: var(--secondary_color);
  border: 1px solid var(--secondary_color);
  font-size: 16px;
  text-transform: capitalize;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s;
  margin-right: 5px;
  line-height: 25px;
  cursor: pointer;
}
.pricing-wrapper .tabs-nav li.react-tabs__tab--selected {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  color: var(--white_color);
}
.pricing-wrapper .pricing-plan-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-column-gap: 30px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box {
  background: var(--white_color);
  padding: 51px 0 55px 0;
  border-radius: 4px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box.active {
  transform: scale(1.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .title {
  font-size: 25px;
  text-transform: capitalize;
  color: var(--secondary_color);
  font-weight: 600;
  margin-bottom: 27px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .price {
  font-size: 70px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 28px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .price sup {
  font-size: 28px;
  top: 3px;
  color: var(--secondary_color);
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .mo {
  text-transform: capitalize;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  opacity: 0.6;
  margin-bottom: 34px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box.active .mo {
  margin-bottom: 42px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .feature-lists {
  margin-bottom: 35px;
  border-top: 1px solid var(--dark_gray);
  padding-top: 32px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .feature-lists li {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
}
.pricing-wrapper
  .pricing-plan-grid
  .pricing-plan-box
  .feature-lists
  li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box.active .theme-button,
.pricing-wrapper .pricing-plan-grid .pricing-plan-box:hover .theme-button {
  background-color: var(--primary_color);
  color: var(--white_color);
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .theme-button {
  padding-left: 45px;
  background-color: var(--white_color);
  color: var(--secondary_color);
  padding-right: 25px;
}
.pricing-wrapper .pricing-plan-grid .pricing-plan-box .theme-button .icon {
  left: 20px;
}
.plain-pricing-table .pricing-wrapper {
  background: #f6f8f9;
  padding: 135px 0 150px 0;
}

/* ----------------------------------------------
          Sign Up
---------------------------------------------- */
.signup-form-wrapper {
  padding: 123px 0 50px;
  position: relative;
  overflow: hidden;
}
.signup-form-wrapper:before,
.signup-form-wrapper:after {
  position: absolute;
  content: "";
  z-index: -1;
  width: 50%;
  height: 60%;
  top: 0;
  left: -30px;
  background-image: url(../images/dots3.png);
  transform: rotate(-10deg);
  opacity: 0.1;
  background-position: center;
}
.signup-form-wrapper:after {
  right: 0;
  left: auto;
  top: auto;
  bottom: 0;
}
.signup-form-wrapper .signup-form-box {
  max-width: 576px;
  margin: auto;
  background-color: var(--white_color);
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 46px 30px 42px 30px;
}
.signup-form-wrapper .signup-form-box .signup-form .checkboxes.d-flex {
  flex-wrap: wrap;
}
.signup-form-wrapper .signup-form-box .signup-form-top {
  margin-bottom: 33px;
}
.signup-form-wrapper .signup-form-box .signup-form-top h1 {
  font-size: 40px;
  color: var(--secondary_color);
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.2;
}
.signup-form-wrapper .signup-form-box .signup-form-top p {
  color: var(--paragraph_color);
  font-size: 18px;
  font-weight: 500;
}
.signup-form-wrapper .signup-form-box .signup-form-signin {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.signup-form-wrapper .signup-form-box .signup-form-signin button {
  padding-right: 50px;
  width: 150px;
  font-weight: 500;
}
.signup-form-wrapper .signup-form-box .signup-form-signin .twitter {
  background-color: #31a5ff;
}
.signup-form-wrapper .signup-form-box .signup-form-signin .facebook {
  background-color: #3b5998;
}
.signup-form-wrapper .signup-form-box .signup-form-signin .google {
  background-color: #d44439;
}
.signup-form-wrapper .signup-form-box .signup-form-signin button:hover {
  background-color: var(--primary_color);
}
.signup-form-wrapper .signup-form-box .connector {
  position: relative;
  margin: 18px 0 20px 0;
}
.signup-form-wrapper .signup-form-box .connector:before {
  content: "";
  position: absolute;
  background-color: #ddd;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
  z-index: 1;
}
.signup-form-wrapper .signup-form-box .connector span {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: var(--paragraph_color);
  font-weight: 500;
  font-size: 22px;
  background-color: var(--white_color);
  z-index: 2;
  position: relative;
}
.signup-form-wrapper .signup-form-box .signup-form .checkboxes {
  margin: 20px 0 36px 0;
}
.checkboxes {
  position: relative;
}
.signup-form-wrapper .signup-form-box .signup-form .checkboxes label a,
.signup-form-wrapper .signup-form-box .already-account a,
.signup-form-wrapper .signup-form-box .signup-form .checkboxes.d-flex a,
.signup-form-wrapper .signup-form-box .signup-form-top p a {
  color: var(--primary_color);
}
.signup-form-wrapper .signup-form-box .signup-form .privacy-policy {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 600;
  line-height: 20px;
}
.signup-form-wrapper .signup-form-box .signup-form .privacy-policy:last-child {
  margin-bottom: 0;
}
.checkboxes input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkboxes .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ddd;
}
.checkboxes input:checked ~ .checkmark {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
}
.checkboxes input:checked ~ .checkmark:after {
  display: block;
}
.checkboxes .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkboxes .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid var(--white_color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.signup-form-wrapper .signup-form-box .already-account {
  margin: 30px 0 0 0;
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
}
.signup-form-wrapper .signup-form-box .already-account.d-flex {
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* login */
.signup-form-wrapper .signup-form-box .signup-form .checkboxes.d-flex {
  align-items: center;
  justify-content: space-between;
}
.signup-form-wrapper
  .signup-form-box
  .signup-form
  .checkboxes.d-flex
  .privacy-policy {
  margin-bottom: 0;
  padding-left: 30px;
}
.signup-form-wrapper .signup-form-box .signup-form .checkboxes.d-flex a {
  font-weight: 500;
}
/* forgot password */
.forgot-password .signup-form-box .signup-form-top p {
  line-height: 30px;
}

/* Checkout */
.checkout-area {
  padding: 120px 0 40px 0;
  position: relative;
}
.checkout-area form {
  position: relative;
}
.checkout-area .checkout-box {
  background-color: var(--white_color);
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 30px 30px;
}
.checkout-area .checkout-box h1 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  color: var(--secondary_color);
  margin-bottom: 26px;
}
.checkout-area .checkout-box .form-field {
  margin-bottom: 1rem;
}
.checkout-area .checkout-box .form-field label {
  font-size: 14px;
  color: var(--secondary_color);
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.checkout-area .billing-content.checkout-box .d-grid {
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-column-gap: 30px;
}
.checkout-area .checkout-box .form-field select {
  width: 100%;
}
.checkout-area .checkout-box .form-field .checkboxes label {
  display: block;
  position: relative;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 600;
  padding-left: 30px;
  cursor: pointer;
}
.checkout-area .checkout-box .form-field .checkboxes .create-account {
  margin-bottom: 15px;
}
.checkout-area .checkout-box .form-field .checkboxes label .checkmark {
  top: 3px;
}
.products-total-summary table {
  width: 100%;
  text-align: left;
}
.products-total-summary table tr th {
  color: var(--secondary_color);
  line-height: inherit;
  border: 0;
  padding: 5px 0;
}
.products-total-summary table tbody.subtotals,
.products-total-summary table thead tr th,
.products-total-summary table tbody.items tr:last-child td,
.products-total-summary table tbody.subtotals tr:last-child td,
.products-total-summary table tbody.subtotals tr:last-child th {
  border-bottom: 1px solid var(--dark_gray);
}
.products-total-summary table thead tr th:last-child,
.products-total-summary table tbody tr td:last-child,
.products-total-summary table tfoot td {
  text-align: right;
  color: var(--paragraph_color);
  line-height: inherit;
  font-weight: 500;
}
.products-total-summary table thead tr th:last-child {
  color: var(--secondary_color);
  font-weight: 600;
}
.products-total-summary table tbody.items tr td {
  padding: 8px 0 2px 0;
  color: var(--paragraph_color);
  font-weight: 500;
  border: 0;
  line-height: inherit;
}
.products-total-summary table tbody.items tr:first-child td {
  padding: 15px 0 2px 0;
}
.products-total-summary table tbody.subtotals tr th,
.products-total-summary table tbody.subtotals tr td {
  padding: 2px 0;
  line-height: inherit;
}
.products-total-summary table tbody.subtotals tr:first-child td,
.products-total-summary table tbody.subtotals tr:first-child th {
  padding-top: 15px;
}
.products-total-summary table tbody.items tr:last-child td,
.products-total-summary table tbody.subtotals tr:last-child td,
.products-total-summary table tbody.subtotals tr:last-child th {
  padding-bottom: 15px;
}
.products-total-summary table tfoot td,
.products-total-summary table tfoot th {
  font-size: 20px;
  padding-top: 15px;
  font-weight: 600;
}
.order-summary-payment-method {
  border-top: 1px solid var(--dark_gray);
  margin: 30px 0 0 0;
  padding: 20px 0 0 0;
}
.order-summary-payment-method .radio-fields {
  margin-bottom: 29px;
}
.order-summary-payment-method .radio-fields label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  font-weight: 500;
  color: var(--paragraph_color);
  padding: 0 0 0 25px;
}
.order-summary-payment-method .radio-fields label input {
  width: 0;
  height: 0;
  margin: 0 5px 0 0;
  opacity: 0;
}
.radio-fields label .checkmark {
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  background: var(--white_color);
  border-radius: 50%;
  border: 2px solid var(--dark_gray);
  transition: all 0.3s;
}
.radio-fields label .checkmark:before {
  content: "";
  background: var(--primary_color);
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -6px;
  margin-top: -6px;
  transform: scale(0);
  transition: all 0.3s;
}
.order-summary-payment-method .radio-fields label input:checked ~ .checkmark {
  border-color: var(--primary_color);
}
.order-summary-payment-method
  .radio-fields
  label
  input:checked
  ~ .checkmark:before {
  transform: scale(1);
}

/* Order Confirm */
.product-ordered {
  position: relative;
}
.product-ordered .product-ordered-thankyou h1 {
  margin-bottom: 25px;
  background: var(--green);
  color: var(--white_color);
  padding: 8px 15px;
  border-radius: 4px;
  text-align: center;
  line-height: 28px;
  font-weight: 500;
  font-size: 16px;
}
.product-ordered-order-details {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  text-align: left;
  margin-bottom: 50px;
  grid-column-gap: 30px;
}
.product-ordered-order-details li h2 {
  text-transform: uppercase;
}
.product-ordered-order-details li {
  border-right: 1px dashed #d3ced2;
  color: var(--paragraph_color);
  font-size: 13px;
}
.product-ordered-order-details li:last-child {
  border-right: none;
}
.product-ordered-order-details li span {
  font-weight: 500;
  font-size: 16px;
  margin-top: 2px;
  display: block;
}
.product-ordered p {
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  font-size: 16px;
}
.order-details-table {
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
}
.product-order-details h1 {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 20px;
}
.order-details-table td,
.order-details-table th {
  border: 1px solid var(--dark_gray);
  font-weight: 700;
  padding: 9px 15px;
  line-height: 1.5em;
  font-size: 17px;
}
.order-details-table tbody tr td,
.order-details-table tbody .product-name {
  font-weight: 400;
}
.order-details-table tbody .product-name a {
  color: var(--paragraph_color);
}
.order-details-table tbody .product-name a:hover {
  color: var(--primary_color);
}
.order-customer-details {
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 30px;
  background-color: var(--white_color);
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 25px 20px;
}
.order-customer-details h1 {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 21px;
}
.order-customer-details address {
  line-height: 28px;
  font-weight: 500;
  padding: 15px 20px;
  border: 1px solid var(--gray_color);
  color: #a0aec6;
  font-style: normal;
  border-radius: 4px;
}
.order-customer-details address p {
  display: flex;
  align-items: center;
}
.order-customer-details address p .icon {
  margin-right: 7px;
}

/* ----------------------------------------------
          Blog
---------------------------------------------- */
.h-blog-wrap {
  padding: 112px 0 26px 0;
}
.blog-wrapper {
  position: relative;
}
.h-blog-wrap .col-4.text-right .theme-button {
  margin-top: 25px;
}
.blog-wrapper .blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 30px;
  margin-top: 27px;
  align-items: flex-start;
}
.blog-wrapper .blog-grid .blog {
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  transition: all 0.3s;
  margin-bottom: 30px;
}
.blog-wrapper .blog-grid .blog .blog-img-box {
  position: relative;
}
.blog-wrapper .blog-grid .blog .blog-img-box:before {
  position: absolute;
  content: "";
  bottom: 4px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--white_color);
  transform: translateX(-50%);
  z-index: 1;
}
.blog-wrapper .blog-grid .blog .blog-img-box img {
  border-radius: 4px 4px 20px 20px;
  width: 100%;
}
.blog-wrapper .blog-grid .blog .blog-img-box .blog-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 98.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(35, 61, 99, 0.9);
  border-radius: 4px 4px 20px 20px;
  transition: all 0.3s;
  transform: scale(0.7);
  opacity: 0;
}
.blog-wrapper .blog-grid .blog:hover .blog-img-box .blog-overlay {
  transform: scale(1);
  opacity: 1;
}
.blog-wrapper .blog-grid .blog .blog-img-box .blog-overlay a {
  background: var(--white_color);
  color: var(--secondary_color);
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
  font-size: 30px;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-meta {
  display: inline-block;
  color: var(--paragraph_color);
  font-size: 15px;
  opacity: 0.7;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-meta a {
  color: var(--secondary_color);
  font-weight: 600;
  transition: all 0.3s;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-meta a:hover {
  color: var(--primary_color);
}
.blog-wrapper .blog-grid .blog .blog-content-box {
  padding: 25px 25px 32px 25px;
  border-radius: 0 0 4px 4px;
  background-color: var(--white_color);
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-title {
  font-size: 22px;
  color: var(--secondary_color);
  font-weight: 700;
  line-height: 28px;
  display: block;
  transition: all 0.3s;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-title:hover,
.blog-wrapper
  .blog-grid
  .blog
  .blog-content-box
  .blog-footer
  .blog-comment
  a:hover,
.blog-wrapper
  .blog-grid
  .blog
  .blog-content-box
  .blog-footer
  .theme-button:hover,
.footer-wrapper .footer-widgets-grid .footer-left .footer-address li a:hover,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-meta
  a:hover {
  color: var(--primary_color);
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-desc {
  font-size: 16px;
  color: var(--paragraph_color);
  margin-top: 28px;
  margin-bottom: 28px;
  line-height: 28px;
  font-weight: 500;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-footer .theme-button {
  padding: 0 15px 0 0;
  line-height: 15px;
  background: none;
  color: var(--secondary_color);
  box-shadow: none;
}
.blog-wrapper
  .blog-grid
  .blog
  .blog-content-box
  .blog-footer
  .theme-button
  .icon {
  right: 0;
  top: 9px;
}
.blog-wrapper
  .blog-grid
  .blog
  .blog-content-box
  .blog-footer
  .theme-button:hover
  .icon {
  right: -3px;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-footer .blog-comment {
  line-height: 12px;
}
.blog-wrapper .blog-grid .blog .blog-content-box .blog-footer .blog-comment a {
  color: var(--secondary_color);
}
/* Blog Full Width */
.blog-full-width .blog-grid {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  max-width: 780px;
  margin: auto auto 40px auto;
}
.blog-wrapper.blog-full-width {
  padding: 123px 0 0 0;
}
/* Blog Grid */
.blog-wrapper.blog-grid-page {
  padding: 123px 0 0 0;
}
.blog-wrapper.blog-grid-page .blog-grid {
  margin: 0 0 40px 0;
}

/* Blog Details */
.blog-details-wrapper {
  padding: 40px 0 30px 0;
}
.blog-details-wrapper .blog-details-grid .blog-details-img-box {
  position: relative;
  border-radius: 4px 4px 20px 20px;
}
.blog-details-wrapper .blog-details-grid .blog-details-img-box:before {
  position: absolute;
  content: "";
  bottom: 7px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--white_color);
  transform: translateX(-50%);
  z-index: 1;
}
.blog-details-wrapper .blog-details-grid .blog-details-img-box img {
  width: 100%;
  border-radius: 4px 4px 20px 20px;
}
.blog-details-wrapper .blog-details-grid .blog-details-contents {
  position: relative;
  padding: 18px 0 0 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-meta {
  color: var(--paragraph_color);
  font-size: 15px;
  opacity: 0.7;
  font-weight: 500;
  text-transform: capitalize;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 5px 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-meta
  a {
  color: var(--secondary_color);
  font-weight: 600;
  transition: all 0.3s;
  margin: 0 5px 0 4px;
}
.blog-details-wrapper .blog-details-grid .blog-details-contents h2 {
  font-size: 25px;
  color: var(--secondary_color);
  font-weight: 700;
  line-height: 28px;
  display: block;
  transition: all 0.3s;
  border-bottom: 1px solid #eee;
  padding-bottom: 26px;
}
.blog-details-wrapper .blog-details-grid .blog-details-contents p {
  font-size: 16px;
  color: var(--paragraph_color);
  margin: 28px 0 30px 0;
  line-height: 28px;
  font-weight: 500;
}
.blog-details-wrapper .blog-details-grid .blog-details-contents blockquote p {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #a0aec6;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-cnt-mdl-grid {
  margin: 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-cnt-mdl-grid
  img {
  width: 100%;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-cnt-mdl-grid
  ul
  li {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-cnt-mdl-grid
  ul
  li
  .icon {
  color: var(--primary_color);
  margin-right: 5px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: space-between;
  margin: 33px 0 0 0;
  padding: 30px 0 30px 0;
  border-top: 1px solid var(--gray_color);
  border-bottom: 1px solid var(--gray_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-left
  p,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  span {
  font-size: 18px;
  color: var(--secondary_color);
  font-weight: 600;
  margin: 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-left
  p
  a {
  display: inline-block;
  color: var(--paragraph_color);
  padding: 5px 18px;
  border-radius: 30px;
  border: 1px solid var(--gray_color);
  text-transform: capitalize;
  font-size: 16px;
  transition: all 0.3s;
  font-weight: 500;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-left
  p
  a:hover,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  > a:hover,
.widget_tags ul li a:hover {
  border-color: var(--primary_color);
  color: var(--white_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li {
  margin-left: 5px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li
  a {
  display: block;
  font-size: 16px;
  color: var(--paragraph_color);
  background-color: rgba(35, 61, 99, 0.1);
  width: 35px;
  height: 35px;
  line-height: 40px;
  position: relative;
  border-radius: 30px;
  text-align: center;
  transition: all 0.3s;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li
  a:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li:last-child
  a {
  font-size: 19px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right {
  align-items: center;
  justify-content: flex-end;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li
  a:hover {
  color: var(--white_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-pagination {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 35px 0 25px 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-pagination
  > * {
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary_color);
  transition: all 0.3s;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-pagination
  > *:hover {
  color: var(--primary_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap {
  position: relative;
  margin: 57px 0 0 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comment-title {
  font-size: 35px;
  color: var(--secondary_color);
  font-weight: 700;
  margin-bottom: 33px;
  line-height: 1.2;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li {
  border: 1px solid var(--gray_color);
  padding: 30px 30px 30px 20px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  ul {
  padding-left: 130px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-avata
  img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  border: 8px solid var(--white_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  h3 {
  font-size: 20px;
  text-transform: capitalize;
  color: var(--secondary_color);
  font-weight: 600;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  h3
  .date {
  font-size: 16px;
  color: var(--paragraph_color);
  font-weight: 500;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  p {
  font-size: 16px;
  font-weight: 500;
  color: var(--paragraph_color);
  margin: 0 0 15px 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  > a {
  color: var(--secondary_color);
  position: relative;
  transition: all 0.3s;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 4px 22px 4px 22px;
  border: 1px solid var(--gray_color);
  border-radius: 30px;
  font-weight: 500;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  > a
  .icon {
  margin-right: 3px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  .helpful {
  align-items: center;
  color: var(--paragraph_color);
  font-size: 14px;
  font-weight: 500;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  .helpful
  a {
  border: 1px solid rgba(128, 137, 150, 0.4);
  border-radius: 4px;
  padding: 4px 12px;
  color: var(--paragraph_color);
  margin: 0 4px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  line-height: 20px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  .helpful
  a:hover {
  background-color: rgba(128, 137, 150, 0.1);
  color: var(--secondary_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  .helpful
  a
  .icon {
  margin-right: 3px;
  color: var(--primary_color);
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  h1 {
  font-size: 35px;
  color: var(--secondary_color);
  font-weight: 700;
  margin: 60px 0 7px 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  p {
  margin: 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form {
  margin: 36px 0 0 0;
  padding: 0;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  label,
.contact-form-wrapper .contact-form-grid .right form label {
  font-size: 14px;
  color: var(--secondary_color);
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 0.5rem 0;
  display: inline-block;
  position: relative;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  label
  sup,
.contact-form-wrapper .contact-form-grid .right form label sup {
  line-height: 10px;
  position: absolute;
  top: 6px;
  right: -8px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  .comment-field-2clmn {
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-column-gap: 30px;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  input:placeholder {
  color: var(--paragraph_color);
  font-weight: 500;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  input {
  color: var(--secondary_color);
  margin-bottom: 1rem;
}
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comment-fields
  form
  textarea {
  height: 170px;
}
/* Blog Sidebar */
.blog-sidebar .blog-sidebar-widget {
  margin-bottom: 40px;
}
.blog-sidebar .blog-sidebar-widget:last-child {
  margin-bottom: 0;
}
.blog-sidebar .blog-sidebar-widget h3 {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--secondary_color);
  margin: 0 0 20px 0;
}
.widget_search form {
  position: relative;
}
.widget_search form button {
  position: absolute;
  right: 20px;
  top: 50%;
  border: 0;
  background-color: transparent;
  color: var(--secondary_color);
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 15px;
}
.widget_search form input {
  color: var(--secondary_color);
}
.widget_search form input::placeholder {
  color: var(--paragraph_color);
}
.widget_category ul li a,
.widget_archive ul li a {
  color: var(--secondary_color);
  font-size: 16px;
  position: relative;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #a0aec6;
  font-weight: 600;
  transition: all 0.3s;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-radius: 10px 0 10px 0;
}
.widget_category ul li a:hover,
.widget_archive ul li a:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
  border-color: var(--primary_color);
  border-radius: 4px;
  padding-right: 16px;
}
.widget_tags ul li {
  display: inline-block;
}
.widget_tags ul li a {
  color: var(--secondary_color);
  font-size: 16px;
  position: relative;
  padding: 8px 20px;
  display: block;
  transition: all 0.3s;
  text-transform: capitalize;
  border-radius: 4px;
  font-weight: 500;
  margin: 0 5px 10px 0;
  border: 1px solid #cccdce;
}
.widget_tags ul li a:hover {
  border-radius: 30px;
}
.blog-sidebar-widget.sidebar-social-profile h3 {
  margin-bottom: 30px;
}

/* ----------------------------------------------
          Contact
---------------------------------------------- */
.contact-form-wrapper {
  position: relative;
  padding: 113px 0 0 0;
}
.contact-form-wrapper .contact-form-grid .sec-heading .sec__title {
  margin-bottom: 28px;
  line-height: 60px;
}
.contact-form-wrapper .contact-form-grid .sec-heading .sec__meta {
  margin-bottom: 8px;
}
.contact-form-wrapper .contact-form-grid {
  grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  grid-column-gap: 30px;
}
.contact-form-wrapper .contact-form-grid .left p {
  font-size: 16px;
  color: var(--paragraph_color);
  line-height: 28px;
  font-weight: 500;
  margin: 0 0 30px 0;
}
.contact-form-wrapper .contact-form-grid .right form .form-grid {
  grid-template-columns: repeat(auto-fit, minmax(257px, 1fr));
  grid-column-gap: 30px;
}
.contact-form-wrapper .contact-form-grid .right form input {
  margin-bottom: 1rem;
}
.contact-form-wrapper .contact-form-grid .right form textarea {
  height: 150px;
}
.contact-form-wrapper .contact-form-map-grid {
  padding: 120px 0 0 0;
}
.contact-form-wrapper .contact-form-map-grid .right aside {
  position: relative;
  border-radius: 4px;
  padding: 30px 35px 36px 35px;
  color: var(--secondary_color);
}
.contact-form-wrapper .contact-form-map-grid .right aside h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 25px;
}
.contact-form-wrapper .contact-form-map-grid .right aside img {
  width: 100%;
  margin: 0 0 17px 0;
}
.contact-form-wrapper .contact-form-map-grid .right aside p {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 26px 0;
}
/* .contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos {
  border-bottom: 1px solid rgba(103, 114, 134, 0.2);
  padding: 0 0 32px 0;
  margin: 0 0 28px 0;
} */
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item {
  align-items: flex-start;
  margin: 0 0 20px 0;
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item:last-child {
  margin-bottom: 0;
}
.contact-sidebar-infos{

  width: 580px;
  height: 380px;
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item
  .icon
  span {
  font-size: 18px;
  left: 0;
  top: 0;
  color: var(--paragraph_color);
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 44px;
  transition: all 0.3s;
  background-color: rgba(35, 61, 99, 0.1);
  border-radius: 50%;
  display: block;
  position: relative;
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item:hover
  .icon
  span {
  background: var(--primary_color);
  color: var(--white_color);
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item
  .icon
  span:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -3px;
  transform: translateX(-50%);
  width: 22px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--white_color);
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item
  .details {
  padding-left: 10px;
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item
  .details
  h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 6px;
  color: var(--secondary_color);
}
.contact-form-wrapper
  .contact-form-map-grid
  .right
  aside
  .contact-sidebar-infos
  .item
  .details
  span {
  display: block;
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
}
.contact-form-wrapper .contact-form-map-grid .right aside button {
  width: 100%;
}
.contact-form-wrapper .contact-form-subscribe-wrap {
  position: relative;
  padding: 106px 0 30px 0;
}
.contact-form-subscribe-wrap .contact-form-subscribe-content {
  max-width: 705px;
  margin: auto;
}
.contact-form-subscribe-wrap h1 {
  color: var(--secondary_color);
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  margin: 0 0 17px 0;
}
.contact-form-subscribe-wrap p {
  color: var(--paragraph_color);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.contact-form-subscribe-wrap .contact-form-subscribe-content form {
  max-width: 566px;
  margin: 30px auto auto auto;
}
.contact-form-subscribe-wrap .contact-form-subscribe-content form > div {
  align-items: center;
}
.contact-form-subscribe-wrap .contact-form-subscribe-content form input {
  margin: 0 10px 0 0;
}
.shop-home .contact-form-subscribe-wrap {
  padding: 108px 0 120px 0;
  max-width: 558px;
  margin: auto;
}
.contact-form-wrapper .contact-form-subscribe-wrap {
  max-width: 558px;
  margin: auto;
}
.shop-home .contact-form-subscribe-wrap .contact-form-subscribe-content {
  max-width: 100%;
}

/* ----------------------------------------------
          Footer
---------------------------------------------- */
.footer-wrapper {
  padding-top: 187px;
  overflow: hidden;
  position: relative;
  background: var(--secondary_color);
}
.footer-wrapper:before {
  content: "";
  width: 105%;
  background: white;
  left: -5px;
  top: -32px;
  height: 130px;
  z-index: 1;
  position: absolute;
  transform: rotate(2deg);
}
.footer-wrapper .footer-widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-column-gap: 30px;
}
.footer-wrapper .footer-widgets-grid .footer-left .desc {
  font-size: 16px;
  color: #a0aec6;
  font-weight: 500;
  margin-top: 20px;
  line-height: 28px;
}
.footer-wrapper .footer-widgets-grid .footer-left .footer-address {
  margin: 30px 0 28px 0;
}
.footer-wrapper .footer-widgets-grid .footer-left .footer-address li {
  margin-bottom: 15px;
}
.footer-wrapper
  .footer-widgets-grid
  .footer-left
  .footer-address
  li:last-child {
  margin-bottom: 0;
}
.footer-wrapper .footer-widgets-grid .footer-left .footer-address li a {
  color: #a0aec6;
  transition: all 0.3s;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.footer-wrapper .footer-widgets-grid .footer-left .footer-address li a .icon {
  width: 40px !important;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white_color);
  border-radius: 50%;
  transition: all 0.3s;
  text-align: center;
  margin-right: 10px;
  position: relative;
}
.footer-wrapper
  .footer-widgets-grid
  .footer-left
  .footer-address
  li
  a
  .icon:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary_color);
}
.footer-wrapper
  .footer-widgets-grid
  .footer-left
  .footer-address
  li
  a:hover
  .icon,
.footer-wrapper .footer-widgets-grid .footer-left .social ul li a:hover,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-left
  p
  a:hover,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-tags-grid
  .blog-details-tags-right
  ul
  li
  a:hover,
.blog-details-wrapper
  .blog-details-grid
  .blog-details-contents
  .blog-details-comments-wrap
  .comments
  li
  .comment-content
  .comment-reply-helpful
  > a:hover,
.widget_tags ul li a:hover {
  background: var(--primary_color);
}
.footer-wrapper .footer-widgets-grid .footer-left .social h2 {
  font-size: 18px;
  color: var(--white_color);
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 18px;
}
.footer-wrapper .footer-widgets-grid .footer-left .social ul {
  display: flex;
  align-items: center;
}
.footer-wrapper .footer-widgets-grid .footer-left .social ul li {
  margin-right: 5px;
}
.footer-wrapper .footer-widgets-grid .footer-left .social ul li:last-child {
  margin-right: 0;
}
.footer-wrapper .footer-widgets-grid .footer-left .social ul li a {
  position: relative;
  display: block;
  color: var(--white_color);
  width: 35px;
  height: 35px;
  line-height: 39px;
  background-color: var(--paragraph_color);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  transition: 0.3s;
}
.footer-wrapper .footer-widgets-grid .footer-left .social ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--secondary_color);
}
.footer-wrapper .footer-widgets-grid .footer-widget h2 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 35px;
  color: var(--white_color);
  margin-top: 10px;
}
.footer-wrapper .footer-widgets-grid .footer-help-links {
  padding-left: 36px;
}
.footer-wrapper .footer-widgets-grid .footer-help-links ul li {
  display: block;
  margin-right: 0;
  margin-bottom: 10px;
}
.footer-wrapper .footer-widgets-grid .footer-help-links ul li a {
  position: relative;
  width: auto;
  height: auto;
  display: inline-block;
  background-color: transparent;
  box-shadow: 0 0;
  border-radius: 0;
  text-transform: capitalize;
  font-size: 16px;
  line-height: inherit;
  font-weight: 500;
  color: #a0aec6;
}
.footer-wrapper .footer-widgets-grid .footer-help-links ul li a:hover {
  background-color: transparent;
  color: var(--white_color);
}
.footer-wrapper .footer-widgets-grid .footer-help-links ul li a:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--white_color);
  transition: all 0.3s;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
}
.footer-wrapper .footer-widgets-grid .footer-help-links ul li a:hover:before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.footer-wrapper .footer-widgets-grid .footer-working-hours .working-hours {
  padding: 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  transition: all 0.3s;
  margin-left: -50px;
  margin-right: 50px;
  color: #a0aec6;
  font-size: 16px;
  font-weight: 500;
}
.footer-wrapper .footer-widgets-grid .footer-working-hours .working-hours li {
  margin-bottom: 5px;
}
.footer-wrapper
  .footer-widgets-grid
  .footer-working-hours
  .working-hours
  li
  strong {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
}
.footer-wrapper
  .footer-widgets-grid
  .footer-working-hours
  .working-hours
  li.off-day {
  margin-top: 24px;
  padding-top: 15px;
  margin-bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-wrapper .footer-widgets-grid .footer-get-in-touch form input,
.footer-wrapper .footer-widgets-grid .footer-get-in-touch form textarea {
  padding: 10px 20px;
}
.footer-wrapper .footer-widgets-grid .footer-get-in-touch form textarea {
  height: 80px;
}
.footer-wrapper .footer-widgets-grid .footer-get-in-touch form button {
  background-color: var(--white_color);
  color: var(--secondary_color);
  width: 100%;
}
.footer-wrapper .footer-widgets-grid .footer-get-in-touch form button:hover {
  background-color: var(--primary_color);
  color: var(--white_color);
}
.footer-wrapper .copyright-wrap {
  position: relative;
  padding: 34px 0 35px 0;
  margin-top: 90px;
  border-top: 1px solid rgba(160, 174, 198, 0.1);
}
.footer-wrapper .copyright-wrap:before,
.footer-wrapper .copyright-wrap:after {
  position: absolute;
  content: "";
  top: -1px;
  left: 0;
  height: 1px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.3);
}
.footer-wrapper .copyright-wrap:after {
  left: auto;
  right: 0;
}
.footer-wrapper .copyright-wrap p {
  font-size: 15px;
  color: #a0aec6;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-wrapper .copyright-wrap p > * {
  margin-left: 5px;
}
.footer-wrapper .copyright-wrap .heartbeat {
  animation: heartBeat 1000ms infinite linear;
  color: #f67e02;
  margin-right: 5px;
}
.footer-wrapper .copyright-wrap a {
  color: var(--white_color);
  position: relative;
  display: inline-block;
}
.footer-wrapper .copyright-wrap a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  bottom: 4px;
  left: 0;
  background-color: var(--white_color);
  transition: all 0.3s;
}
.footer-wrapper .copyright-wrap a:hover:before {
  width: 100%;
}

/* ====================================================
        Error
==================================================== */
.error-wrap {
  background: #f7f7f7;
  position: relative;
}
.error-wrap .error-circle {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  box-shadow: 0 10px 40px rgba(39, 80, 254, 0.2);
  background-color: var(--white_color);
  overflow: hidden;
}
.error-wrap .error-circle:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/dots3.png);
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}
.error-wrap .error-circle:nth-child(1) {
  top: -5px;
  left: -10px;
}
.error-wrap .error-circle:nth-child(2) {
  top: 20%;
  left: 16%;
  width: 70px;
  height: 70px;
}
.error-wrap .error-circle:nth-child(3) {
  top: 55%;
  left: 6%;
  width: 60px;
  height: 60px;
}
.error-wrap .error-circle:nth-child(4) {
  top: auto;
  bottom: 8%;
  left: 15%;
  width: 50px;
  height: 50px;
}
.error-wrap .error-circle:nth-child(5) {
  top: 10px;
  left: auto;
  right: 5px;
}
.error-wrap .error-circle:nth-child(6) {
  top: 25%;
  left: auto;
  right: 15%;
  width: 70px;
  height: 70px;
}
.error-wrap .error-circle:nth-child(7) {
  top: auto;
  left: auto;
  bottom: 33%;
  right: 6%;
  width: 60px;
  height: 60px;
}
.error-wrap .error-circle:nth-child(8) {
  top: auto;
  left: auto;
  bottom: 4%;
  right: 14%;
  width: 45px;
  height: 45px;
}
.header-error-wrapper {
  align-items: center;
  justify-content: space-between;
  padding: 30px 120px 0 120px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-wrap: wrap;
}
.header-error-wrapper .right .links {
  align-items: center;
  flex-wrap: wrap;
}
.header-error-wrapper .right .links li {
  margin-left: 10px;
}
.header-error-wrapper .right .links li:first-child {
  margin-left: 0;
}
.header-error-wrapper .right .links li a {
  color: var(--secondary_color);
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s;
  position: relative;
  padding-bottom: 4px;
}
.header-error-wrapper .right .links li a:hover {
  color: var(--primary_color);
}
.header-error-wrapper .right .links li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary_color);
  transition: all 0.3s;
}
.header-error-wrapper .right .links li a:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}
.error-content-wrapper .error-content-width {
  width: 50%;
  margin: auto;
  padding: 50px 0 80px 0;
  position: relative;
}
.error-content-wrapper .error-content-width img {
  width: 80%;
}
.error-content-wrapper .error-content-width h1 {
  color: var(--secondary_color);
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1.2;
}
.error-content-wrapper .error-content-width p {
  color: var(--paragraph_color);
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin: auto auto 32px auto;
  width: 435px;
}
.error-content-wrapper .error-content-width p a {
  color: var(--primary_color);
}
.error-content-wrapper .error-content-width .connector {
  margin-bottom: 40px;
}
.error-content-wrapper .error-content-width .connector span {
  width: 60px;
  height: 60px;
  color: var(--secondary_color);
  font-size: 35px;
  font-weight: 600;
  line-height: 55px;
  background-color: #fceaee;
  opacity: 0.8;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}
.error-content-wrapper .error-content-width .connector span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 10px;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.error-footer-wrapper {
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--gray_color);
  padding: 40px 0;
}
.error-footer-wrapper:before,
.error-footer-wrapper:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 1px;
  left: 0;
  top: -1px;
  background: rgba(103, 114, 134, 0.3);
}
.error-footer-wrapper:after {
  left: auto;
  right: 0;
}
.error-footer-wrapper p {
  color: var(--paragraph_color);
  font-weight: 500;
  font-size: 14px;
}
.error-footer-wrapper p a,
.error-footer-wrapper p .icon,
.error-footer-wrapper .right .pages li a:hover {
  color: var(--primary_color);
}
.error-footer-wrapper .right .pages {
  align-items: center;
}
.error-footer-wrapper .right .pages li {
  border-right: 1px solid #ddd;
  padding: 0 10px;
  line-height: 20px;
}
.error-footer-wrapper .right .pages li:first-child {
  padding-left: 0;
}
.error-footer-wrapper .right .pages li:last-child {
  padding-right: 0;
  border: none;
}
.error-footer-wrapper .right .pages li a {
  color: var(--paragraph_color);
  transition: all 0.3s;
  position: relative;
  padding-bottom: 5px;
  text-transform: capitalize;
  font-weight: 500;
}
.error-footer-wrapper .right .pages li a:before {
  content: "";
  position: absolute;
  background: var(--primary_color);
  width: 0;
  height: 2px;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
}
.error-footer-wrapper .right .pages li a:hover:before {
  width: 100%;
  left: 0;
  right: auto;
}

@media only screen and (max-width: 320px) {
  .services-items-grid,
  .chart-wrapper .chart-grid,
  .ctaform-wrapper .ctaform-grid,
  .about-wrapper .about-iconbox-grid,
  .about-wrapper .about-grid-wrap,
  .pricing-wrapper .pricing-plan-grid,
  .blog-wrapper .blog-grid,
  .services-items-grid,
  .chart-wrapper .chart-grid,
  .ctaform-wrapper .ctaform-grid,
  .about-wrapper .about-iconbox-grid,
  .about-wrapper .about-grid-wrap,
  .pricing-wrapper .pricing-plan-grid,
  .blog-wrapper .blog-grid,
  .advancetab-wrapper .advancetab-grid .advancetab-content .content-box-grid {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr)) !important;
  }
  .header-area .header-menu-wrapper {
    border-radius: 0;
  }

  .history-area .history-details-box {
    width: 100%;
    padding: 23px 20px 35px 20px;
    position: relative;
  }
  .history-area {
    top: 0 !important;
  }
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin: 0 !important;
  }
  .advancetab-wrapper .advancetab-grid .advancetab-content {
    padding-left: 0;
  }
  .footer-wrapper .copyright-wrap p {
    align-items: flex-start;
  }
  .footer-wrapper .copyright-wrap p svg {
    margin-top: 5px;
  }
  .team-details-wrapper
    .team-details-grid-top
    .team-details-content
    .team-expertise-education-grid {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
  .team-details-wrapper .team-details-grid-top .team-details-img {
    grid-column: span 4 !important;
  }
  .breadcrumb-wrapper .breadcrumb-content h2,
  .shop-featured-product-wrapper .shop-featured-product-grid h1 {
    font-size: 35px;
  }
  .service-content-wrap .service-content h1 {
    line-height: 35px;
  }
  .shop-featured-product-wrapper
    .shop-featured-product-grid
    .shop-featured-left-mdl-grid
    .left,
  .shop-featured-product-wrapper
    .shop-featured-product-grid
    .shop-featured-left-mdl-grid
    .right {
    padding-left: 0;
  }
  .widget_search form button {
    right: 0;
  }
  .header-area
    .header-menu-wrapper
    .header-menu-flex
    .header-menu-right
    .header-menu-right-flex
    .header-menu-cart
    .cart__items {
    right: -95px;
  }
  .hero-slider-wrapper
    .hero-slider-item
    .hero-slider-content
    .hero-slider-btns
    .theme-button {
    padding: 0 30px 0 14px;
    line-height: 45px;
  }
  .hero-slider-wrapper
    .hero-slider-item
    .hero-slider-content
    .hero-slider-btns
    .theme-button
    .icon {
    right: 14px;
  }
  .hero-slider-wrapper
    .hero-slider-item
    .hero-slider-content
    .hero-slider-btns
    .theme-button:hover
    .icon {
    right: 12px;
  }
}
@media (max-width: 425px) {
  .header-area .header-bar-wrapper {
    padding: 15px 0 10px 0;
  }
  .breadcrumb-wrapper .breadcrumb-content h2 {
    font-size: 40px;
  }
  .service-content-wrap
    .service-content
    .service-content-planning-grid
    .planning-lists
    h3 {
    margin-top: 30px !important;
  }
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    .review-fields
    form
    .ratings-review
    .ratings-star {
    min-height: 30px;
  }
  .product-details-item-wrapper
    .product-details-item
    .product-details-item-content
    .product-details-quantity-flex,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    ul
    li
    .details
    .helpful-btns
    p,
  .product-cart-wrapper .product-cart-table-foot,
  .product-cart-wrapper .product-cart-table-foot .left form,
  .header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .product-details-tabs {
    flex-wrap: wrap;
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li
    .comment-content
    h3,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li
    .comment-content
    .comment-reply-helpful,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    > ul
    > li,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    ul
    li
    .details
    h3 {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    ul
    li
    .details
    .rating,
  .header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul {
    justify-content: center;
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-tags-grid
    .blog-details-tags-right {
    justify-content: flex-start;
  }
  .signup-form-wrapper .signup-form-box .signup-form-signin button,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li
    .comment-content
    .comment-reply-helpful
    > a,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .product-details-tabs
    li,
  .product-cart-wrapper .product-cart-table-foot .left,
  .product-cart-wrapper .product-cart-table-foot .left input,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-tags-grid
    .blog-details-tags-left,
  .service-content-wrap .service-content .service-details-tab .tabs-nav li {
    margin-bottom: 10px;
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comment-fields
    p {
    line-height: 23px;
    margin-top: 20px;
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li
    .comment-avata
    img,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    > ul
    > li
    .image,
  .product-cart-wrapper .product-cart-table-foot .left input {
    margin-right: 0;
  }
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    > ul
    > li
    .image {
    margin: auto;
  }
  .signup-form-wrapper .signup-form-box .connector,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    li
    .comment-content
    h3 {
    margin-top: 8px;
  }
  .header-error-wrapper {
    padding: 30px 20px 0 20px;
  }
  .error-content-wrapper .error-content-width p {
    width: auto;
  }
  .error-content-wrapper .error-content-width img {
    margin-top: 60px;
  }
  .ctaform-wrapper .ctaform-grid .ctaform-right {
    padding: 35px 25px;
  }
  .shop-home .contact-form-subscribe-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .breadcrumb-wrapper .breadcrumb-content ul,
  .signup-form-wrapper .signup-form-box .signup-form-signin {
    width: 100%;
    justify-content: center;
  }
  .breadcrumb-wrapper {
    padding-top: 255px;
  }
  .h-service,
  .h-service .service-button,
  .cta-wrapper h1,
  .cta-wrapper .col-4.text-right,
  .sec-heading,
  .h-blog-wrap .col-4.text-right,
  .header-error-wrapper {
    text-align: center;
  }
  .about-wrapper .sec-heading,
  .contact-form-wrapper .contact-form-grid .sec-heading,
  .chart-wrapper .chart-grid .sec-heading {
    text-align: left;
  }
  .hero-slider-wrapper .hero-slider-item {
    /* text-align: center; */
    height: auto;
    padding: 265px 0 100px 0;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex {
    flex-direction: column;
  }
  .hero-slider-wrapper
    .hero-slider-item
    .hero-slider-content
    .hero-slider-btns
    .theme-button:first-child {
    margin-right: 5px;
  }
  .hero-slider-wrapper .hero-slider-item .hero-slider-content h1,
  .service-content-wrap .service-content h1 {
    line-height: 38px;
    font-size: 30px;
  }
  .hero-slider-wrapper .hero-slider-item .hero-slider-content p {
    line-height: 25px;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 30px;
  }
  .about-st1-wrapper .about-right,
  .services-items-grid,
  .team-details-wrapper
    .team-details-grid-top
    .team-details-content
    .team-expertise-education-grid
    .right {
    margin-top: 40px;
  }
  .about-st1-wrapper .about-right img,
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex img,
  .chart-wrapper .chart-grid .chart-right #chart-1,
  .about-st1-wrapper .about-st1-grid,
  .about-st1-wrapper .about-st1-grid > *,
  .history-area .history-details-box,
  .error-content-wrapper .error-content-width,
  .about-wrapper .about-right .about_img_0 {
    width: 100%;
  }
  .history-area .history-details-box {
    padding: 20px 20px 30px 20px;
  }
  .about-wrapper .about-right .about_img_1 {
    left: 10px;
  }
  .about-st1-wrapper .about-st1-grid,
  .team-wrapper .team-grid,
  .portfolio-details-wrapper .portfolio-details-project-lists,
  .portfolio-details-wrapper .related-projects-wrap .related-projects-grid,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-cnt-mdl-grid,
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comment-fields
    form
    .comment-field-2clmn,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    .review-fields
    form
    .review-rating-field-grid {
    position: relative;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .modal-video .modal-video-movie-wrap .modal-video-close-btn,
  .about-st1-wrapper .about-right .video-button-box {
    right: 0;
  }
  .about-st1-wrapper .about-st1-grid > * {
    padding: 0;
  }
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex,
  .header-error-wrapper,
  .contact-form-subscribe-wrap .contact-form-subscribe-content form > div,
  .shop-sidebar-grid .shop-sidebar-product-ordering {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul li {
    margin-right: 20px;
    margin-bottom: 5px;
  }
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex
    .header-bar-social
    li:last-child,
  .contact-form-subscribe-wrap
    .contact-form-subscribe-content
    form
    > div
    input {
    margin-right: 0;
  }
  .hero-slider-wrapper .owl-nav,
  .testimonial-wrapper .shape_img,
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex
    .header-bar-cta-btn,
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex
    .header-bar-language {
    display: none;
  }
  .footer-wrapper .footer-widgets-grid .footer-working-hours .working-hours {
    margin-left: 0;
  }
  .footer-wrapper {
    background: linear-gradient(
      2deg,
      var(--secondary_color) 95%,
      var(--white_color) 50%
    ) !important;
  }
  .header-area .header-bar-wrapper .header-bar-grid,
  .service-content-wrap .service-content .service-content-mdl-grid,
  .service-content-wrap .service-content .service-content-planning-grid,
  .service-content-wrap .service-content .service-content-project-grid,
  .checkout-area .billing-content.checkout-box .d-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .contact-form-wrapper .contact-form-grid > .right {
    padding-right: 10px;
  }
  .services-items-grid,
  .chart-wrapper .chart-grid,
  .ctaform-wrapper .ctaform-grid,
  .about-wrapper .about-iconbox-grid,
  .about-wrapper .about-grid-wrap,
  .pricing-wrapper .pricing-plan-grid,
  .blog-wrapper .blog-grid,
  .about-history-wrapper .about-history-grid,
  .service-slider-wrapper .service-slider-grid,
  .shop-category-collection-grid,
  .shop-featured-product-wrapper .shop-featured-product-grid,
  .contact-form-wrapper .contact-form-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .contact-form-wrapper .contact-form-grid .sec-heading .sec__title {
    line-height: 45px;
  }
  .pricing-wrapper .pricing-plan-grid .pricing-plan-box.active {
    transform: scale(1);
  }
  .shop-featured-product-wrapper .shop-featured-product-grid > .left,
  .shop-featured-product-wrapper .shop-featured-product-grid > .right {
    padding: 0 15px 0 10px;
  }
  .counterup-wrapper .counterup-bottom-wrap p,
  .shop-category-collection-grid .left,
  .contact-form-subscribe-wrap .contact-form-subscribe-content form > div input,
  .shop-sidebar-grid .shop-sidebar-product-ordering .ordering-left,
  .product-details-item
    .product-details-item-content
    .product-details-quantity-flex
    .product-details-quantity,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .reviews
    .review-fields
    form
    .ratings-review
    .ratings-star {
    margin-bottom: 15px;
  }
  .cta-wrapper h1 {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .sec-heading .sec__title,
  .contact-form-subscribe-wrap h1 {
    line-height: 40px;
  }
  .about-st1-wrapper .about-left .about-left-title,
  .history-area .history-details-box .history-details-title,
  .sec-heading .sec__title,
  .signup-form-wrapper .signup-form-box .signup-form-top h1,
  .contact-form-subscribe-wrap h1,
  .shop-featured-product-wrapper .shop-featured-product-grid h1 {
    font-size: 30px;
    word-break: break-word;
  }
  .header-area
    .header-menu-wrapper
    .header-menu-flex
    .header-menu-right
    .header-menu-right-flex
    .header-menu-searchform.active {
    top: 45px;
  }
  .h-service .service-button,
  .h-blog-wrap .col-4.text-right .theme-button,
  .history-area {
    margin-top: 0;
  }
  body {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .history-area {
    top: 200px;
  }
  .modal-video .modal-video-body,
  .modal-video .modal-video-movie-wrap iframe {
    width: 100%;
  }
  .about-wrapper .about-iconbox-grid,
  .ctaform-wrapper .ctaform-grid .ctaform-contact-info-grid,
  .service-content-wrap .service-content .service-content-mdl-grid,
  .service-content-wrap .service-content .service-content-project-grid {
    grid-row-gap: 30px;
  }
  .product-ordered-order-details,
  .about-history-wrapper
    .about-history-grid
    .about-history-right
    .about-history-right-img-grid {
    grid-row-gap: 20px;
  }
  .about-wrapper {
    padding-bottom: 120px;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-right {
    padding: 35px 40px 40px 40px;
  }
  .pricing-wrapper {
    background: linear-gradient(-3deg, #f6f8f9 97%, var(--white_color) 50%);
  }
  .ctaform-wrapper {
    padding-top: 230px;
  }
  .h-blog-wrap .sec-heading .sec__title {
    margin-bottom: 15px;
  }
  .footer-wrapper .footer-widgets-grid .footer-help-links,
  .service-slider-wrapper .service-slider-right,
  .advancetab-wrapper .advancetab-grid .advancetab-content {
    padding-left: 0;
  }
  .service-slider-wrapper .service-slider-right,
  .advancetab-wrapper .advancetab-grid .advancetab-content,
  .team-details-wrapper .team-details-grid-bottom .right,
  .faq-grid .right,
  .blog-wrapper .blog-grid,
  .blog-details-wrapper .blog-details-grid .blog-details-right,
  .shop-sidebar-wrapper .shop-sidebar-grid .left,
  .product-details-item-content,
  .checkout-area .order-summary,
  .contact-form-wrapper .contact-form-map-grid .right,
  .shop-sidebar-wrapper .shop-sidebar-grid .left,
  .service-details-wrapper .service-details-grid .service-sidebar {
    margin-top: 50px;
  }
  .order-customer-details .right {
    margin-top: 20px;
  }
  .team-details-wrapper .team-details-grid-top .team-details-content {
    margin-top: 60px;
    padding-left: 0;
  }
  .advancetab-wrapper .advancetab-grid .advancetab-nav,
  .team-details-wrapper .team-details-grid-bottom .right,
  .shop-sidebar-wrapper .shop-sidebar-grid .left,
  .service-details-wrapper .service-details-grid .service-sidebar {
    grid-column: span 4;
  }
  .service-video-wrapper {
    overflow: hidden;
  }
  .about-history-wrapper
    .about-history-grid
    .about-history-right
    .about-history-trusted-box
    h1 {
    font-size: 30px;
    line-height: 1.3;
  }
  .about-history-wrapper .about-history-grid .about-history-left .sec-heading,
  .service-slider-wrapper .service-slider-right .sec-heading {
    text-align: left;
  }
  .offset-2,
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin-left: 0 !important;
  }
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin-right: 0 !important;
    border-radius: 0;
    margin-top: 0;
  }
  .team-details-wrapper .team-details-grid-top .team-details-img {
    grid-column: span 3;
    width: 100%;
  }
  .faq-grid .right,
  .blog-details-wrapper .blog-details-grid .blog-details-right,
  .contact-form-wrapper .contact-form-map-grid .right {
    grid-column: span 4;
  }
  .service-content-wrap .service-content .service-details-tab .tabs-nav li,
  .product-details-tabs-wrapper
    .product-tabs-width
    .react-tabs
    .product-details-tabs
    li {
    padding: 10px 27px;
  }
  .portfolio-grid-wrapper .grid .grid-item {
    margin-right: 0;
    width: 100%;
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-details-comments-wrap
    .comments
    ul {
    padding-left: 0;
  }
  .cart-table {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .cart-table table {
    width: 1000px;
  }
  .contact-form-wrapper .contact-form-map-grid .left > div {
    height: 400px !important;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid .left,
  .service-details-wrapper .service-details-grid .service-sidebar {
    order: 2;
    width: 100%;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid .right,
  .service-details-wrapper .service-details-grid .service-content-wrap {
    order: 1;
    width: 100%;
  }
  .service-details-wrapper .service-details-grid .service-content-wrap {
    margin-top: 0 !important;
  }
  .checkout-area .checkout-box .form-field {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .header-area .header-bar-wrapper {
    padding: 20px 0;
  }
  .modal-video .modal-video-movie-wrap .modal-video-close-btn {
    right: 0;
  }
  .contact-form-wrapper .contact-form-map-grid .left > div {
    height: 400px !important;
  }
  .cart-table {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .cart-table table {
    width: 1000px;
  }
  .portfolio-grid-wrapper .grid .grid-item {
    width: 48%;
  }
  .portfolio-grid-wrapper .grid .grid-item:nth-child(3),
  .portfolio-grid-wrapper .grid .grid-item:nth-child(6),
  .portfolio-grid-wrapper .grid .grid-item:nth-child(9),
  .portfolio-grid-wrapper .grid .grid-item:last-child {
    margin-right: 20px;
  }
  .portfolio-grid-wrapper .grid .grid-item:nth-child(2),
  .portfolio-grid-wrapper .grid .grid-item:nth-child(4),
  .portfolio-grid-wrapper .grid .grid-item:nth-child(6),
  .portfolio-grid-wrapper .grid .grid-item:nth-child(8),
  .portfolio-grid-wrapper .grid .grid-item:last-child {
    margin-right: 0;
  }
  .service-details-wrapper .service-details-grid .service-sidebar {
    grid-column: span 4;
  }
  .error-content-wrapper .error-content-width p {
    width: auto;
  }
  .team-details-wrapper .team-details-grid-top .team-details-content {
    margin-top: 60px;
    padding-left: 0;
  }
  .offset-2,
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin-left: 0 !important;
  }
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin-right: 0 !important;
    border-radius: 0;
    margin-top: 0;
  }
  .service-video-wrapper {
    overflow: hidden;
  }
  .advancetab-wrapper .advancetab-grid .advancetab-content {
    grid-column: span 3;
  }
  .service-slider-wrapper .service-slider-right {
    padding-left: 0;
    margin-top: 50px;
  }
  .about-wrapper {
    padding-bottom: 120px;
  }
  .modal-video .modal-video-body,
  .modal-video .modal-video-movie-wrap iframe {
    width: 100%;
  }
  .header-area .header-bar-wrapper .header-bar-grid .header-bar-left ul,
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex {
    text-align: right;
    justify-content: center;
  }
  .header-area
    .header-bar-wrapper
    .header-bar-grid
    .header-bar-right
    .header-bar-right-flex {
    margin-top: 15px;
  }
  .header-area
    .header-menu-wrapper
    .header-menu-flex
    .header-menu-right
    .header-menu-right-flex
    .header-menu-searchform.active {
    top: 45px;
  }
  .about-wrapper .about-grid-wrap .about-right,
  .team-details-wrapper .team-details-grid-bottom .right,
  .faq-grid .right,
  .about-st1-wrapper .about-right,
  .blog-details-wrapper .blog-details-grid .blog-details-right,
  .product-details-item-content,
  .checkout-area .order-summary {
    margin-top: 40px;
  }
  .faq-grid .right {
    grid-column: span 3;
  }
  .about-st1-wrapper .about-right {
    max-width: 600px;
  }
  .shop-category-collection-grid .shop-category-collection-item {
    margin-bottom: 30px;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex {
    flex-wrap: wrap;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-right {
    padding: 35px 40px 40px 40px;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-left {
    padding: 40px 40px 0 40px;
  }
  .counterup-wrapper .counterup-grid-wrap,
  .chart-wrapper .chart-grid,
  .pricing-wrapper .pricing-plan-grid,
  .footer-wrapper .footer-widgets-grid,
  .product-ordered-order-details,
  .service-content-wrap .service-content .service-content-mdl-grid,
  .service-content-wrap .service-content .service-content-project-grid {
    grid-row-gap: 40px;
  }
  .portfolio-details-wrapper
    .portfolio-details-image-box
    .portfolio-details-info-grid,
  .portfolio-details-wrapper .related-projects-wrap .related-projects-grid {
    grid-row-gap: 20px;
  }
  .pricing-wrapper .pricing-plan-grid {
    max-width: 500px;
    margin: auto;
  }
  .cta-wrapper h1 {
    margin-bottom: 20px;
  }
  .pricing-wrapper {
    padding: 150px 0 0 0;
    background: linear-gradient(-3deg, #f6f8f9 97%, var(--white_color) 50%);
  }
  .ctaform-wrapper {
    padding: 220px 0 120px 0;
    background: linear-gradient(-3deg, var(--white_color) 87%, #f6f8f9 50%);
  }
  .ctaform-wrapper .ctaform-grid .ctaform-right,
  .contact-form-wrapper .contact-form-map-grid .right {
    margin-top: 30px;
  }
  .footer-wrapper {
    background: linear-gradient(
      2deg,
      var(--secondary_color) 93%,
      var(--white_color) 50%
    );
  }
  .footer-wrapper .footer-widgets-grid .footer-working-hours .working-hours {
    margin-left: 0;
  }
  .offset-2 {
    margin-left: 0 !important;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex {
    flex-direction: column;
  }
  .tabs-wrapper .tabs-content-wrap .tabs-content-flex .tabs-right {
    padding: 35px 40px 40px 40px;
  }
  .col-4.text-right {
    text-align: left;
  }
  .h-blog-wrap .col-4.text-right .theme-button {
    margin-top: 0;
  }
  .service-details-wrapper .service-details-grid .service-content-wrap {
    margin-top: 0 !important;
  }
  .h-blog-wrap .sec-heading .sec__title {
    margin-bottom: 15px;
  }
  .blog-wrapper .blog-grid,
  .service-content-wrap .service-content .service-content-project-grid .right,
  .shop-sidebar-wrapper .shop-sidebar-grid .left {
    margin-top: 50px;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid,
  .service-details-wrapper .service-details-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid .left,
  .service-details-wrapper .service-details-grid .service-sidebar {
    order: 2;
    width: 100%;
  }
  .shop-sidebar-wrapper .shop-sidebar-grid .right,
  .service-details-wrapper .service-details-grid .service-content-wrap {
    order: 1;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .portfolio-grid-wrapper .grid .grid-item {
    margin-right: 20px;
    height: auto;
  }
  .header-style2 .header-bar-wrapper,
  .header-style2 .header-menu-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
  .about-history-wrapper .about-history-grid .about-history-right,
  .service-content-wrap
    .service-content
    .service-content-planning-grid
    .planning-lists
    h3,
  .service-details-wrapper .service-details-grid .service-content-wrap {
    margin-top: 50px;
  }
  .about-st1-wrapper .about-right {
    max-width: 600px;
    margin-top: 40px;
  }
  .about-wrapper .about-grid-wrap .about-right,
  .contact-form-wrapper .contact-form-grid > .right {
    margin-top: 40px;
  }
  .counterup-wrapper .counterup-grid-wrap,
  .chart-wrapper .chart-grid,
  .pricing-wrapper .pricing-plan-grid,
  .footer-wrapper .footer-widgets-grid {
    grid-row-gap: 40px;
  }
  .pricing-wrapper {
    padding-bottom: 0;
  }
  .ctaform-wrapper {
    background: linear-gradient(-3deg, var(--white_color) 89%, #f6f8f9 50%);
  }
  .ctaform-wrapper .ctaform-grid .ctaform-right {
    margin-top: 30px;
  }
  .footer-wrapper {
    background: linear-gradient(
      2deg,
      var(--secondary_color) 92%,
      var(--white_color) 50%
    );
  }
  .blog-details-wrapper
    .blog-details-grid
    .blog-details-contents
    .blog-cnt-mdl-grid
    img {
    margin-bottom: 25px;
  }
}
@media (max-width: 1200px) {
  .header-area .header-menu-wrapper {
    background: var(--white_color);
  }
  .header-area
    .header-menu-wrapper
    .header-menu-flex
    .header-menu-right
    .header-menu-right-flex
    .header-menu-cart
    ul
    li {
    padding-left: 25px;
  }
  .side-menu-open {
    display: block;
  }
  .header-area .header-menu-wrapper .header-menu-flex .header-menu-right > nav {
    display: none;
  }
  .header-area .header-menu-wrapper .header-menu-flex {
    padding: 10px 0;
  }
}
/* .tabs-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
} */

/* Add this style to your existing CSS or create a new stylesheet */
.tabs-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  border: 2px solid #ccc; /* Use your desired border color */
  border-radius: 10px; /* Add rounded corners for a softer look */
  padding: 20px;
  margin: 10px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out; /* Add a smooth transition effect on hover */
}

.card:hover {
  transform: scale(1.05); /* Scale up the card slightly on hover for a subtle effect */
}

.card p {
  font-weight: bold; /* Make the text bold */
  margin-bottom: 10px; /* Add spacing between paragraphs */
}


/* .blog-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.blog-details-right img {
  width: 100%;
  height: 10;
  margin:30px;
  padding: 0px;
;
} */

Add this to your CSS file
.blog-details-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.author-image-container {
  display: flex;
  align-items: center;
  gap: 100px; /* Adjust the gap as needed */
}

.author-image-container img {
  max-width:300px; /* Adjust the width as needed */
  height: auto;
}
.name{
  color: black;
}
.names{
  color: var(--paragraph_color);
}
/* Add or update these styles in your existing CSS or create a new stylesheet */
.disclaimer-container {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0a0a0a;
  padding: 23px;
  text-align: center;
  width: 900px;
  color:white;
  z-index: 999; /* Ensure it's above other elements */
}

.disclaimer-container button {
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  text-align: center;
  cursor: pointer;
}

.disclaimer-container button:hover {
  background: #0056b3;
}
 #bottom-table {
  position: fixed;
  width: 40%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 1px 10px 10px #888888; */
  z-index: 20;
  justify-content: center;
  bottom: 5px;
  background: #eee;
  font-size: 12px;
  /* padding: 15px 15px; */
}
#bottom-table i {
  /* Your base styles here */
  position: absolute;
  top: 1px;
  color: black;
  right: 1px;
  border-radius: 50%;
  float: right;
  font-size: 30px;
  cursor: pointer;
}

#bottom-table i:hover {
  /* Styles for when the element is hovered over */
  background-color: lightgray;
  color: red;
}

#bottom-table i:active {
  /* Styles for when the element is being clicked */
  transform: scale(0.9); /* Example: scales the element down slightly on click */
}


/* #bottom-table i {
  position: absolute;
  top: 1px;
  color: red;
  right:1px;
  border-radius: 50%;
  float: right;
  font-size: 17px;
  cursor: pointer;
} */
/* body {
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
  color: #10161A;
} */
/* #bottom-table {
  position: fixed;
  width: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 10px 10px #888888;
  z-index: 20;
  justify-content: center;
  bottom: 0;
  background: #eee;
  font-size: 12px;
  /* padding: 15px 15px; */
/* } */
/* #bottom-table table {
  margin-bottom: unset !important;
} */


.table {
    width: 100%;
    max-width: 100%;
    /* margin-bottom: 20px */
}
/*
.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd
} */

/* .table-bordered {
  border: 1px solid #ddd
} */
th {
  text-align: left
}
.table-bordered>tbody>tr>td,.table-bordered>tbody>tr>th,.table-bordered>tfoot>tr>td,.table-bordered>tfoot>tr>th,.table-bordered>thead>tr>td,.table-bordered>thead>tr>th {
  border: 1px solid #ddd
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;

}
 .cta-buttons {
  position: absolute;
  bottom: 130px;
  right: 40px;
  border-radius: 70px;
  background-color: #ffffff;
  /* transition: background-color 0.3s ease, transform 0.3s ease; */
}
 .cta-buttons:hover {
  border-radius: 70px;
  /* transform: translateY(15px); */
}

.cta1-buttons {
  position: absolute;
  top: 150px;
  right: 50px;
  border-radius: 70px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
 .cta1-buttons:hover {
  border-radius: 70px;
  transform: translateY(15px);
}
.cta2-buttons {
  position: absolute;
  top: 370px;
  right: 0px;
  border-radius: 70px;

  /* transition: background-color 0.3s ease, transform 0.3s ease; */
}
 .cta2-buttons:hover {
  border-radius: 70px;
  transform: translateY(15px);

}

.touch_form {
  position: absolute;
  bottom: 200px;
  right: 40px;
  border-radius: 70px;
  background-color: #ffffff;
  /* transition: background-color 0.3s ease, transform 0.3s ease; */
}
 .touch_form:hover {
  border-radius: 70px;
  /* transform: translateY(15px); */
}

/* Add these styles to your existing CSS file */

.modal {
  display: none;
  position: fixed;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  z-index: 9999;
  width: 350px;
  height:230px;
  padding: 20px;
  margin-right: 0%;
  font-family: "Montserrat", sans-serif;
}
.form-title {
  /* Add margin at the bottom of the form title */
  margin-bottom: 10px;
}
.modal-content {
  background-color:white;
  padding: 20px;
  border: 6px solid var(--primary_color);
}

.close {
  color:black;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  right: 30px;
  top:30px;

}

/* Center align the form elements */
.modal-content form {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.modal-content input[type="text"],
.modal-content input[type="tel"],
.modal-content input[type="email"] {
  height: 50px; /* Adjust the height as needed */
  margin-bottom: 20px; /* Add some spacing between input fields */
  /* border: 2px solid var(--primary_color);  */
}

.get-in-touch-container {
  position: fixed;
  top: 400px;
  right: 25px;
  z-index: 100;
}
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #C39239;
  color: #fff;
  overflow: hidden;
  border: none;
  cursor: pointer;
}
.button-title {
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  background-color: #c39239;
  color: #fff;
  padding: 6px;
  white-space: nowrap;
  transition: opacity 0.3s ease, left 0.3s ease;
  opacity: 0;
  font-size:12px;
}
button:hover + .button-title {
  right: calc(100% + 10px);
  opacity: 1;
}
    .Invest {
      position: fixed;
      top: 470px;
      right: 25px;
      z-index: 10;
    }
    .invest-link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-decoration: none;
      color: #fff;
      background-color: #c39239;
      padding: 10px;
    }
    .invest-icon {
      font-size: 24px;
    }
    .know-icon {
      font-size: 24px;
    }
    .invest-title {
      position: absolute;
      top: 50%;
      right: calc(100% + 10px);
      transform: translateY(-50%);
      background-color: #c39239;
      color: #fff;
      padding: 6px;
      white-space: nowrap;
      transition: opacity 0.3s ease, left 0.3s ease;
      opacity: 0;
      font-size:12px;
    }
    .invest-link:hover + .invest-title {
      right: calc(100% + 10px);
      opacity: 1;
    }
    .know-link:hover + .know-title {
      right: calc(100% + 10px);
      opacity: 1;
    }
    .know-title {
      position: absolute;
      top: 50%;
      right: calc(100% + 10px);
      transform: translateY(-50%);
      background-color: #c39239;
      color: #fff;
      padding: 6px;
      white-space: nowrap;
      transition: opacity 0.3s ease, left 0.3s ease;
      opacity: 0;
      font-size:12px;
    }
  .know {

    position: fixed;
    top: 540px;
    right: 27px;
    z-index: 10;
  }

  .know:hover {
    cursor: pointer;
  }

  .know img {
    cursor: pointer;
  }
 .author-benchmark-container{
  display:"flex";
  width:"100%";
  height:"10%";
  margin-Bottom:"-120px"
 }
 @media (max-width: 430px) and (orientation: portrait) {

  .contact-sidebar-infos{
    width: fit-content;
    height: fit-content;

  }
  .bar{
    width:fit-content;
    height: "320px";
    justify-Content: "center";
     display: "flex";
     margin-Top: "-70px";
      margin-Left: "15%"
  }
  .author-benchmark-container {
    flex-direction: column;
    height: auto;
    margin-left: -10%;
  }
.disclaimer-container {
  width: 80%;
  top:20%;
  font-size:x-small;


}
.disclaimer-container button {
  text-align: center;
}

  .author-container  {
    width: 100%;
  }
  .blog-details-wrapper .blog-details-grid .blog-details-contents p{
    font-size: 11px;
  }
  .speedometer-container{
    width: 100%;
    height: auto;
    margin-left: 20%;
  }
  .inv{
    flex-direction: column;
  }
  .author-benchmark-container{
  font-size: x-small;
    }
  .service-widgets {
    display: none;
  }
  .left {
    width: 100%;
    font-size:x-small;
    margin-bottom: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contact-form-wrapper .contact-form-map-grid .right{
    grid-column: span 4;
}
.contact-form-map-grid .right {
  max-width: 100%;
  margin-left: -5%;
  margin-right: 0;
  margin-top: -50%;

}
  .team-container,
  .team-container1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #bottom-table {
    width: 100%;
    font-size: 10px;
    margin-bottom: 0%;
  }
  .hero-slider-wrapper .hero-slider-item {
    padding-top: 60px;
    padding-bottom: 60px;
    height: 100vh;
  }
  .get-in-touch-container, .Invest, .know
  {
    width: 15%;
  }
  .container{
    width: 100%;
    margin-top: 10%;
  }
  .tabs-right,.tabs-left{
    width: 100%;
  }
  .tabs-card {
    width: calc(100% - 20px);
    margin: 10px 0;
  }
  .card {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    flex-basis: 100%;
    text-align: center;
  }
  .tabscards{
    display: none;
  }
  .tabscards-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.card {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 330px;
  height: 70%;
  text-align: center;
}
.card-content {
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.arrow {
  margin-top: 20px;
}
.contact-form-wrapper {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
}
 .contact-form-wrapper {
    padding: 20px;
  }
  .contact-form-map-grid {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .contact-form-map-grid .left {
    max-width: 100%;
    margin-top: 0;
    margin-left: -5%;
  }
  .contact-sidebar-infos{
    width: fit-content;
    height: fit-content;

  }

  /* FEE MODEL STYLE CSS SHEET */

.pdf-download-format {
  width: 1200px; 
  max-width: none; 
  overflow: visible;
  margin: 0 auto; 
}
.fee-model-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4; 
  width: 100%; 
  min-height: 100vh; 
  font-weight: 500;
}
.fee-model-container  h2 {
  margin-top:3%;
  font-weight: 500;
}
.fee-model-p{
  margin-top:10%;
  font-size: 15px;
  font-weight: 800;

  
}
.investment-input{
  margin-top:1%;
  color: var(--paragraph_color);
}
.table-h{
  color: var(--secondary_color);
  text-align:center;
  padding: 3px;
  width: 60%;
  margin: 0% auto;
}
.back-button{
  color: white;
  text-align:center;
  padding: 3px;
  width: 10%;
  margin-left: 80%;  
}
.back-button1{
  color: white;
  text-align:center;
  padding: 3px;
  width: 10%;
  margin-left: 90%;  
  position: relative;
  top: 75%;
  margin-bottom: 5px;

}
.fee-model {
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin-top: 1%;
  overflow: hidden;
}
.fee-model table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0;
  margin-top: 10px;
  border-radius: 8px; 
  overflow: hidden;
  background-color: #f9f9f9;
}
.fee-model th, .fee-model td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
  border-right: 1px solid #eee; 
}
.fee-model th {
  background-color: var(--primary_color);
  color: var(--secondary_color);
  font-weight: bold;
  border-bottom: 2px solid #ddd; 
}
.fee-model td:last-child, .fee-model th:last-child {
  border-right: none; 
}
.fee-model tbody tr {
  border: 1px solid #ddd;
  border-radius: 8px; 
  margin-bottom: 10px;
}
.fee-model tbody tr:hover {
  background-color: #f1f1f1; 
}
.fee-model td {
  color: #333;
}
.fee-model h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.6rem; 
  color: var(--primary_color); 
}
.fee-model1 {
  background-color: #ffffff; 
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); 
  width: 50%; 
  margin-top: 5%; 
}
.fee-model h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.6rem; 
  color: var(--primary_color); 
}
.assumptions-table 
.scenario-table{
  width: 100%; 
  border-collapse: collapse; 
}
.scenario-table td:first-child,
.scenario-table td:nth-child(3)
 {
  text-align: left;
}

.assumptions-table th,
.scenario-table th,
.scenario-table td,
.assumptions-table td ,.fee-model td,.fee-model th,
.scenario-table td:nth-child(2){
  padding: 1px; 
  text-align: center; 
  border: 1px solid #ddd; 
}

.assumptions-table th ,
.scenario-table th{
  background-color: var(--primary_color); 
  color: var(--secondary_color); 
}

.assumptions-table tr:nth-child(even)
.scenario-table tr:nth-child(even) {
  background-color: #f9f9f9; 
}

.assumptions-table tr:hover,
.scenario-table tr:hover {
  background-color: #f1f1f1; 
}
.form-group {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px; 
}

.form-group input,
.form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; 
  transition: all 0.3s ease-in-out;
}

.form-group input:hover,
.form-group select:hover {
  border-color: var(--primary_color);
}

.Submit {
  display: block;
  width: 100%; 
  padding: 3px;
  background-color: var(--primary_color); 
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px; 
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15); 
}

.Submit:hover {
  background-color: var(--secondary_color);
  transform: translateY(-2px); 
}

.fee-details h4 {
  text-align: center;
  margin-top: 18px;
  font-size: 1.4rem; 
  color: var(--primary_color); 
}

.fee-details p {
  font-size: 14px;
  margin: 5px 0;
  text-align: center;
}

.fee-summary-container {
  max-width: 100%;
  margin: 5% auto;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.fee-summary-container h2 {
  text-align: center;
  font-size: 1.8rem; 
  color:var(--secondary_color);
  margin-bottom: 20px;
}

.fee-summary-container p {
  font-size: 1.1rem;
  color: #666;
}

.assumptions-table,
.scenario-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.scenario-table {
  margin-top: 0;
}
.assumptions-table td{
  padding: 4px;
}
.assumptions-table th,
.scenario-table th {
  background-color: var(--primary_color);
  color: var(--secondary_color); 
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

.scenario-table td {
  padding: 5px;
  text-align: center;
}

.notes-section {
  margin-top: 30px;
}

.notes-section h4 {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 10px;
}
.notes-section h6 {
  font-size: 1.2rem;
  color: #444;
  margin-top: 5px;
}
.notes-section p {
  font-size: 1.2rem;
  color: #444;
  margin-top: 20%;
}


.notes-section ul {
  padding-left: 20px;
  list-style-type: disc;
  margin-left: 20px; 
}

.notes-section ul li {
  font-size: 0.5;
  color: #555;
  margin-bottom: 10px;
}

.scenario-table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 768px) {
  .fee-model-container {
    margin-top: 40%;
    padding: 10px;
    width: 100%;
  }
  .fee-model {
    padding: 15px;
    margin-top: 5%;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  }
  .fee-model h3 {
    font-size: 1.4rem; 
    margin-top:50%;
  }
  .fee-model-container  h2 {
    font-size: 1.4rem; 
    margin-top:10%;
  }
  .form-group input,
  .form-group select {
    font-size: 12px;
    padding: 8px;
  }
  button {
    font-size: 14px;
    padding: 10px;
    width: auto;

  table, thead, tbody, th, td, tr {
    display: block;
    width: 100%;
  }
  thead {
    display: none;
  }
  tr {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  td {
    display: flex;
    flex-direction: column; 
    padding: 10px;
    border-bottom: 1px solid #eee;
    text-align: center;
    justify-content: center;
  }
  td::before {
    content: attr(data-label); 
    font-weight: bold;
    margin-bottom: 5px; 
    text-align: center; 
  }
  .scenario-table-container,
  .assumptions-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  }
  .scenario-table-container::-webkit-scrollbar,
  .assumptions-table-container::-webkit-scrollbar {
    height: 8px;
  }
  .scenario-table-container::-webkit-scrollbar-thumb,
  .assumptions-table-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .fee-model {
    width: 100%;
    padding: 15px;
  }
  .fee-model h3 {
    font-size: 1.2rem; 
  }
  .assumptions-table, .scenario-table {
    font-size: 10px; 
  }
  .assumptions-table th, .scenario-table th, 
  .assumptions-table td, .scenario-table td {
    padding: 10px; 
    width: 100%;
    margin: 10%;
  }
}
.mobile-card-view{
  display: none;
  margin-top: -20%;
}

@media (max-width: 768px) {
  .desktop-content ,
  .download-button{
    display: none;
  }
  .back-button{
    width: 20%;
  }
  .back-button1{
    display: none;
  }
  .container-h2{
    text-align: center;
    margin: 0% auto;
  }
 
  .fee-model1 {
    display: block;
  }
  
.fee-model-p{
  display: none;
}
.fee-model,
.fees {
  display: none;
}
.mobile-card-view {
  display: block;
}
.mobile-card-view h2 {
text-align: center;
margin-bottom: 10%;
}

  .fee-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
  }
  .fee-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .fee-card h3 {
    font-size: 1.4rem;
    margin-top: 0;
    font-weight: 600;
    color: var(--secondary_color);
  }
  .fee-card p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
    font-weight: 500;
  }
  .fee-card button {
    width: 80%;
    margin: 0 auto;
    padding: 12px;
    background:  var(--primary_color);
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 15px;
    cursor: pointer;
  }
  .fee-card button:hover {
    background-color: var(--secondary_color); 
    transform: translateY(-2px); 
  }
  .fee-card button:focus,
  .fee-card button:active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.desktop-content{
  display: none;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 20px; 
}
.logo-container {
  margin-right: 20px; 
}
.logo {
  width: 100px; 
}
.fee-table-wrapper {
  overflow-x: auto; 
}

.desktop-view {
  display: table;
}

.mobile-card-view {
  display: none;
}

.mobile-view {
  display: none;
}

@media only screen and (max-width: 768px) {
  
  .desktop-view,
  .ul1 ,
  .ap,
  .additional-info{
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .fee-card {
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .fee-card h3 {
    margin-top: 0;
  }

  button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  table, thead, tbody, th, td, tr {
    
    width: 10%;
  }
  .fee-table2 {
    font-size: 12px; 
  }

  .fee-table2 th, .fee-table2 td {
    padding: 8px; 
  }

  .fee-table2 {
    width: 10%; 
    overflow-x: auto; 
  }

.fee-table2 {
  width: 100%;
  margin-top: 10%; 
  border: 1px solid #ddd;
  border-collapse: collapse;
  line-height: 10px;
}

.fee-table2 th, .fee-table2 td {
  padding: 2px; 
  text-align: center;
  border: 1px solid black; 
  font-size: 10px; 
}
.fee-table2 th{
  padding: 0px; 
  text-align: center; 
  border: 1px solid black;
  font-size: 6px; 
  background-color: var(--primary_color);
  font-weight: bold;
  text-align: center;
}
}
.ul1 {
  list-style: none;
  padding-left: 10;
  text-align: center;
  margin-top: 1%;
}
.ul1 li {
  font-size: 16px;
  color: #495057;
  margin-bottom: 10px;
  text-align: center;
}
ul li a {
  color: var(--primary_color);
  text-decoration: none;
}
ul li a:hover {
  text-decoration: underline;
}
.additional-info {
text-align: center;
  margin: 0 auto;
  width: 30%
}
.download-excel-btn {
  margin-top: 10px;
  padding: 10px;
  background-color: #c39239;
  color: var(--secondary_color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.fee-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0 auto;
  justify-content: center;
}
.fee-details-table th, .fee-details-table td {
  border: 1px solid #ccc;
  padding: 1px;
  text-align: left;
}
.fee-details-table th {
  background-color: var(--primary_color);
  font-weight: bold;
  text-align: center;
}
.fee-details-table td:nth-child(2) {
text-align: right;
}